import React, { FC } from 'react';
import { Container, Row, Spinner } from 'reactstrap';


const Loader = () => {
  return(
  <Container fluid className=" d-flex align-items-center">
    <Row className="justify-content-center align-self-center w-100 text-center">
      <Spinner color="primary" />
    </Row>
  </Container>
  );
}

export default Loader;
