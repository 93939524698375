import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Formik, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import { Button, Card, CardBody, FormGroup, Label } from "reactstrap";
import { toastr } from "react-redux-toastr";
import styles from "styled-components";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetAuthState, resetPassword } from "../../actions/auth";
import md5 from "md5-hash";
import setAuthToken from "../../utils/setAuthToken";
import { TOASTR_ERROR_OPTIONS } from "../../constant";

const ResetPassword = ({ auth, history, resetPassword, resetAuthState }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmedPasswordShown, setConfirmedPasswordShown] = useState(false);

  const [hasValidLength, setHasValidLength] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [criteriasFulfilled, setCriteriasFulfilled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formikRef = useRef(null);

  const onSubmit = (values) => {
    setIsSubmitting(true);

    setAuthToken(localStorage.reset_password_token);
    resetPassword(md5(values.newPassword))
      .then(() => {
        history.replace("/reset-password-success");
      })
      .catch((error) => {
        toastr.error("", error.message, TOASTR_ERROR_OPTIONS);
      }).finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleCancel = () => {
    resetAuthState();
    history.replace("/");
  };

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmedPassword = () => {
    setConfirmedPasswordShown(!confirmedPasswordShown);
  };

  const onChangeNewPassword = (password) => {
    // Password should have minimum length of 8
    if (password.length >= 8) {
      setHasValidLength(true);
    } else {
      setHasValidLength(false);
    }

    // Password should contain at least one uppercase letter
    if (/[A-Z]/.test(password)) {
      setHasUppercase(true);
    } else {
      setHasUppercase(false);
    }

    // Password should contain at least one lowercase letter
    if (/[a-z]/.test(password)) {
      setHasLowercase(true);
    } else {
      setHasLowercase(false);
    }

    // Password should contain at least one lowercase letter
    if (/\d/.test(password)) {
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }

    // If all the criterias are  satisfied.. enable the confirm button
    if (
      hasLowercase &&
      hasUppercase &&
      hasNumber &&
      hasValidLength &&
      passwordsMatch
    ) {
      setCriteriasFulfilled(true);
    } else {
      setCriteriasFulfilled(false);
    }
  };

  // Added additional validations on confirmed new password for disabling confirm button
  const onChangeConfirmedNewPassword = (password, confirmedPassword) => {
    // Password fields should not be empty or null or undefined and they should match
    if (
      (password != null || password !== "" || password !== undefined) &&
      (confirmedPassword != null ||
        confirmedPassword !== "" ||
        confirmedPassword !== undefined) &&
      password === confirmedPassword
    ) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }

    // If all the criterias are  satisfied.. enable the confirm button
    if (
      hasLowercase &&
      hasUppercase &&
      hasNumber &&
      hasValidLength &&
      passwordsMatch
    ) {
      setCriteriasFulfilled(true);
    } else {
      setCriteriasFulfilled(false);
    }
  };

  useEffect(() => {
    if (auth?.error) {
      toastr.error("An error has occured.", auth?.error, TOASTR_ERROR_OPTIONS);
    }
  }, [auth]);

  const newPasswordSchema = yup.object({
    newPassword: yup.string().required("Please enter your new password"),
    confirmedNewPassword: yup
      .string()
      .required("Please enter your new password again")
      .oneOf(
        [yup.ref("newPassword"), null],
        "Passwords don’t match, please check."
      ),
  });

  if (!localStorage.reset_password_token) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Formik
        innerRef={(instance) => {
          formikRef.current = instance;
        }}
        initialValues={{
          newPassword: "",
          confirmedNewPassword: "",
        }}
        onSubmit={onSubmit}
        validationSchema={newPasswordSchema}
      >
        {(props) => {
          const { touched, errors, values } = props;
          return (
            <Container className="d-flex justify-content-center align-items-center flex-columns vh-100">
              <CardStyle>
                <CardBody className="p-3">
                  <Form autoComplete="off" className="mx-3">
                    <FormHeader>Set new password</FormHeader>
                    <FormGroupStyle>
                      <FormLabel>New Password</FormLabel>
                      <div className="position-relative">
                        <FieldStyle
                          name="newPassword"
                          type={passwordShown ? "text" : "password"}
                          handleChange={onChangeNewPassword(values.newPassword)}
                        />
                        {passwordShown ? (
                          <Icon onClick={togglePassword}>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/ShowEye.png`}
                              alt="showEye"
                              width="24"
                              height="18"
                            />
                          </Icon>
                        ) : (
                          <Icon onClick={togglePassword}>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/HideEye.png`}
                              alt="hideEye"
                              width="24"
                              height="18"
                            />
                          </Icon>
                        )}
                        {/* <i onClick={togglePassword} className={`fa ${passwordShown ? 'fa-eye-slash' : 'fa-eye'}`} /> */}
                        {touched.newPassword && errors.newPassword && (
                          <div className="text-danger">
                            {errors.newPassword}
                          </div>
                        )}
                      </div>
                    </FormGroupStyle>
                    <FormGroupStyle>
                      <FormLabel>Confirmed New Password</FormLabel>
                      <div className="position-relative">
                        <FieldStyle
                          name="confirmedNewPassword"
                          type={confirmedPasswordShown ? "text" : "password"}
                          handleChange={onChangeConfirmedNewPassword(
                            values.newPassword,
                            values.confirmedNewPassword
                          )}
                        />
                        {confirmedPasswordShown ? (
                          <Icon onClick={toggleConfirmedPassword}>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/ShowEye.png`}
                              alt="showEye"
                              width="24"
                              height="18"
                            />
                          </Icon>
                        ) : (
                          <Icon onClick={toggleConfirmedPassword}>
                            <img
                              src={`${process.env.PUBLIC_URL}/images/HideEye.png`}
                              alt="hideEye"
                              width="24"
                              height="18"
                            />
                          </Icon>
                        )}
                        {touched.confirmedNewPassword &&
                          errors.confirmedNewPassword && (
                            <div className="text-danger">
                              {errors.confirmedNewPassword}
                            </div>
                          )}
                      </div>
                    </FormGroupStyle>

                    <FormLabel>Password must:</FormLabel>
                    <div>
                      <div className="position-relative">
                        {hasValidLength ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/images/checked.png`}
                            alt="checked"
                            width="16"
                            height="16"
                          />
                        ) : (
                          <img
                            src={`${process.env.PUBLIC_URL}/images/unchecked.png`}
                            alt="checked"
                            width="16"
                            height="16"
                          />
                        )}
                        <FormLabel className="px-2">
                          Be a minimum of 8 characters
                        </FormLabel>
                      </div>
                      <div className="position-relative">
                        {hasLowercase ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/images/checked.png`}
                            alt="checked"
                            width="16"
                            height="16"
                          />
                        ) : (
                          <img
                            src={`${process.env.PUBLIC_URL}/images/unchecked.png`}
                            alt="checked"
                            width="16"
                            height="16"
                          />
                        )}
                        <FormLabel className="px-2">
                          Include at least one lowercase letter (a-z)
                        </FormLabel>
                      </div>
                      <div className="position-relative">
                        {hasUppercase ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/images/checked.png`}
                            alt="checked"
                            width="16"
                            height="16"
                          />
                        ) : (
                          <img
                            src={`${process.env.PUBLIC_URL}/images/unchecked.png`}
                            alt="checked"
                            width="16"
                            height="16"
                          />
                        )}
                        <FormLabel className="px-2">
                          Include at least one uppercase letter (A-Z)
                        </FormLabel>
                      </div>
                      <div className="position-relative">
                        {hasNumber ? (
                          <img
                            src={`${process.env.PUBLIC_URL}/images/checked.png`}
                            alt="checked"
                            width="16"
                            height="16"
                          />
                        ) : (
                          <img
                            src={`${process.env.PUBLIC_URL}/images/unchecked.png`}
                            alt="checked"
                            width="16"
                            height="16"
                          />
                        )}
                        <FormLabel className="px-2">
                          Include at least one number (0-9)
                        </FormLabel>
                      </div>
                    </div>
                    <FormGroup className="mt-5 mb-2 d-flex justify-content-between">
                      <ButtonStyle
                        className="w-40"
                        color="cancel"
                        type="button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </ButtonStyle>
                      <ButtonStyle
                        className="w-40"
                        color="primary"
                        type="submit"
                        disabled={!criteriasFulfilled || isSubmitting}
                      >
                        Confirm
                      </ButtonStyle>
                    </FormGroup>
                  </Form>
                </CardBody>
              </CardStyle>
              {/* <Footer className="p-3 bg-white text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/powered_by.png`}
                  alt="footer"
                />
              </Footer> */}
            </Container>
          );
        }}
      </Formik>
    </>
  );
};

const FormGroupStyle = styles(FormGroup)`
  text-align: left;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;
const FormLabel = styles(Label)`
  font-size: 19px;
  margin-bottom: 12px;
`;
const FieldStyle = styles(Field)`
  height: 60px;
  padding: 10px;
  width: 100%;
  border: none;
  background-color: #EDF2F7;
  margin-bottom: 5px;
  position: relative;
`;

const FormHeader = styles.h1`
  font-size: 32px;
  font-family: Roboto Condensed;
  color: #5A6168; 
  font-weight: 400;
`;

const ButtonStyle = styles(Button)`
  width: 200px;
  height: 50px;
  top: 542px;
`;

const CardStyle = styles(Card)`
  width: 500px;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  @media (max-width: 414px) {
    height: 100%;
    background: #fbf9fe
  }
`;
const Container = styles.div`
  background-color: #f5f9fb
`;

const Icon = styles.div`
top: 16px;
right:12px;
position: absolute;
`;

const Footer = styles.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  @media (max-width: 414px) {
    bottom: 0;
  }
`;

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  resetAuthState: PropTypes.func.isRequired,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth, //whtever is required from reducer
});

export default connect(mapStateToProps, { resetPassword, resetAuthState })(
  withRouter(ResetPassword)
);
