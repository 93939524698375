import React, { useEffect, useState } from "react";
import { CardBody, Card, Button, FormGroup } from "reactstrap";
import styled from "styled-components";
import { Input } from "../form";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  currencyFormat,
  generateLeadAge,
  getFormattedDiffBetweenMoments,
} from "../../utils/helpers";
import {
  LEAD_CATEGORY_COLOR,
  LEAD_SCORE_COLOR,
  LEAD_SCORE_MAPPING,
} from "../../constant";
import { emiCalculator, processingFeesCalculator } from "../../utils/helpers";
import { CUSTOMER_INTEREST_LEVEL } from "../../constant";

const ContactRecord = ({
  editableAddress,
  setEditableAddress,
  toggleAddress,
  data,
}) => {
  const { handleReset, handleSubmit, values, setValues, isSubmitting, errors } =
    useFormikContext();

  const cancelEditAddress = () => {
    toggleAddress();
    if (data?.address) {
      setValues({
        ...values,
        ["address"]: data.address,
      });
    }
  };

  useEffect(() => {
    if (data?.address) {
      setValues({
        ...values,
        ["address"]: data.address,
      });
    }
  }, [data]);

  const handleSubmitTemp = (e) => {
    const submitType = e.target.id;
    setValues({
      ...values,
      submitType: submitType,
    });
  };

  const formatBirthdayDate = (date) => {
    if (!date) {
      return "";
    }

    const dateArr = date.split("-");

    if (!dateArr[0] || !dateArr[1] || !dateArr[2]) {
      return "";
    }

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthString = months[Number(dateArr[1]) - 1];

    if (!monthString) {
      return "";
    }

    return dateArr[2] + " " + monthString + " " + dateArr[0];
  };

  const formatCurrency = (value) => {
    if (value === null || value === undefined || Number.isNaN(Number(value))) {
      return "-";
    }

    return currencyFormat(value) + " PHP";
  };

  return data ? (
    <Card>
      <CardBody className="p-0">
        <div className="pt-4">
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Customer Name</div>
            <div>{data?.firstName + " " + data?.lastName || "--"}</div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Customer Account Number</div>
            <div>{data?.accountNumber || "--"}</div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Phone Number</div>
            <div>{data?.phoneNumber || "--"}</div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Birthday</div>
            <div>{formatBirthdayDate(data?.birthday) || "--"}</div>
            {/* <div>{moment(data?.birthday).format('DD MMMM YYYY').isValid() ? moment(data?.birthday).format('DD MMMM YYYY') : '--'}</div> */}
          </div>
          <div>
            {!editableAddress ? (
              <div className="px-4 mb-3">
                <div style={{ color: "#2A2A3E" }}>Address</div>
                <div>{data?.address || "--"}</div>
                <Button
                  color="link"
                  className="border-0 p-0 pl-0"
                  onClick={() => setEditableAddress(true)}
                >
                  <u>Edit</u>
                </Button>
              </div>
            ) : (
              <div
                className="px-2 py-4 mb-3"
                style={{ backgroundColor: "rgba(42, 42, 62, 0.05)" }}
              >
                <div className="px-3">
                  <Input type="textarea" id="address" label="Address" />
                </div>

                <div className="d-flex justify-content-end mt-4 px-3">
                  <Button
                    color="cancel"
                    className="text-danger border-0"
                    onClick={cancelEditAddress}
                    style={{ backgroundColor: "transparent" }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={isSubmitting}>
                    Save Address
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Branch</div>
            <div>{data?.branchName || "--"}</div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Sales Agent</div>
            <div>
              {data?.recentContactAttempt?.salesAgent?.firstName
                ? data?.recentContactAttempt?.salesAgent?.firstName +
                  " " +
                  data?.recentContactAttempt?.salesAgent?.lastName
                : data?.salesAgent?.firstName +
                  " " +
                  data?.salesAgent?.lastName}
            </div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Lead Stage</div>
            <div>
              {data?.recentContactAttempt?.salesStage ||
                data?.salesStage ||
                "--"}
            </div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Lead Age</div>
            <div style={{ whiteSpace: "pre-line" }}>
              {data?.createdAt
                ? generateLeadAge(data) +
                  `\n(${moment(data?.createdAt).format("DD/MM/YYYY, hh:mm a")})`
                : "--"}
            </div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Contact Stage</div>
            <div>{data?.recentContactAttempt?.contactTag || "Not started"}</div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Days Since Contact</div>
            <div style={{ whiteSpace: "pre-line" }}>
              {data?.timestampOfLastContact
                ? getFormattedDiffBetweenMoments(
                    moment(),
                    moment(data?.timestampOfLastContact)
                  ) +
                  `\n(${moment(data?.timestampOfLastContact).format(
                    "DD/MM/YYYY, hh:mm a"
                  )})`
                : "--"}
            </div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Preferred Calling Date</div>
            <div style={{ whiteSpace: "pre-line" }}>
              {data?.recentContactAttempt?.nextContactDate &&
              moment(data?.recentContactAttempt?.nextContactDate).isValid()
                ? moment(data?.recentContactAttempt?.nextContactDate).format(
                    "DD/MM/YYYY"
                  ) +
                  "\n" +
                  data?.recentContactAttempt?.nextContactTime
                : "--"}
            </div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Lead Category</div>
            <div>
              {data?.leadCategory ? (
                <span
                  style={{
                    color: LEAD_CATEGORY_COLOR[data?.leadCategory],
                    fontWeight: "bold",
                  }}
                >
                  {data?.leadCategory?.charAt(0).toUpperCase() +
                    data?.leadCategory?.slice(1)}
                </span>
              ) : (
                "--"
              )}
            </div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: "#2A2A3E" }}>Last Interest Level</div>
            <div>
              {data?.recentContactAttempt?.customerInterestLevel
                ? CUSTOMER_INTEREST_LEVEL.find((element) => {
                    return (
                      element.value ===
                      data?.recentContactAttempt?.customerInterestLevel
                    );
                  })?.label
                : "--"}
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between px-4">
          <h3>Loan Information</h3>
        </div>
        <Group className="d-flex justify-content-between p-2 px-4 even">
          <Label>Maturity Date</Label>
          <Value>
            {moment(data?.maturityDate).isValid()
              ? moment(data?.maturityDate).format("DD/MM/YYYY")
              : "-"}
          </Value>
        </Group>
        <Group className="d-flex justify-content-between p-2 px-4">
          <Label>Last Date Of Payment</Label>
          <Value>
            {moment(data?.lastDateOfPayment).isValid()
              ? moment(data?.lastDateOfPayment).format("DD/MM/YYYY")
              : "-"}
          </Value>
        </Group>
        <Group className="d-flex justify-content-between p-2 px-4 even">
          <Label>Past Due</Label>
          <Value>{formatCurrency(data?.pastDue)}</Value>
        </Group>
        <Group className="d-flex justify-content-between p-2 px-4">
          <Label>Total Amount Due</Label>
          <Value>{formatCurrency(data?.totalAmountDue)}</Value>
        </Group>
        <Group className="d-flex justify-content-between p-2 px-4 even">
          <Label>
            Total Amount Due
            <br />
            As of{" "}
            {data?.totalAmountDueAsOfMonthAndYear
              ? data?.totalAmountDueAsOfMonthAndYear[1]
              : "(month)"}{" "}
            {data?.totalAmountDueAsOfMonthAndYear
              ? data?.totalAmountDueAsOfMonthAndYear[2]
              : ""}
          </Label>
          <Value>
            {data?.totalAmountDueAsOfMonthAndYear
              ? formatCurrency(data?.totalAmountDueAsOfMonthAndYear[0])
              : "-"}
          </Value>
        </Group>
        <Group className="d-flex justify-content-between p-2 px-4">
          <Label>Penalty Balance</Label>
          <Value>{formatCurrency(data?.penaltyBalance)}</Value>
        </Group>
        <Group className="d-flex justify-content-between p-2 px-4 even">
          <Label>Total Penalty</Label>
          <Value>{formatCurrency(data?.totalPenalty)}</Value>
        </Group>

        <hr />
        <div className="pb-4">
          <div className="d-flex justify-content-between px-4">
            <h3>Recommended Loan Offer</h3>
          </div>
          <Group className="d-flex justify-content-between p-2 px-4 even">
            <Label>Loan amount</Label>
            <Value>
              {formatCurrency(Math.ceil(data?.loanOfferAmount || 0))}
            </Value>
          </Group>
          <Group className="d-flex justify-content-between p-2 px-4">
            <Label>Loan tenure</Label>
            <Value>{data?.loanTenure || "-"} months</Value>
          </Group>
          <Group className="d-flex justify-content-between p-2 px-4 even">
            <Label>Loan interest rate</Label>
            <Value>{data?.loanInterestRate || "-"}%</Value>
          </Group>
          <Group className="d-flex justify-content-between p-2 px-4">
            <Label>EMI</Label>
            <Value>
              {formatCurrency(
                emiCalculator(
                  Number(data?.loanOfferAmount || 0),
                  Number(data?.loanTenure || 0),
                  Number(data?.loanInterestRate || 0)
                )
              )}
            </Value>
          </Group>
          <Group className="d-flex justify-content-between p-2 px-4 even">
            <Label>Processing Fee</Label>
            <Value>
              {formatCurrency(
                processingFeesCalculator(Number(data?.loanOfferAmount || 0))
              )}
            </Value>
          </Group>
        </div>
      </CardBody>
    </Card>
  ) : (
    <div>Loading ...</div>
  );
};

const Group = styled.div`
  background-color: #fff;
  &.even {
    background-color: rgba(42, 42, 62, 0.05);
  }
  &.total {
    background-color: #ffcc4d;
  }
`;

const Label = styled.div`
  color: rgba(42, 42, 62, 0.75);
  opacity: 0.7;
`;

const Value = styled.div`
  color: #5a6168;
`;
ContactRecord.propTypes = {
  data: PropTypes.any,
};
export default ContactRecord;
