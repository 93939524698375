import React, { useState } from "react";
import { FormGroup, Button, Col } from "reactstrap";
import { useFormikContext, Field } from "formik";
import { Input, Select, Datepicker } from "../form";
import { SHOW_LOGO, TIME_RANGE } from "../../constant";
import styled from "styled-components";

import wheeltek_logo from "../../img/WG LOGO.jpg";
import prospect from "../../reducers/prospect";

const ProspectResponse = ({ loanAmount, isMobile, isLoading }) => {
  const { values, setValues, isSubmitting, handleSubmit } = useFormikContext();
  const [agree, setAgree] = useState(true);

  const minDate = new Date();
  minDate.setUTCDate(minDate.getUTCDate() + 1);

  const Heading = styled.div`
    color: red;
    font-size: ${isMobile ? "6vw" : "2.5vw"};
    font-weight: bold;
    font-style: italic;
  `;

  const SubHeading = styled.div`
    color: black;
    font-size: ${isMobile ? "2.8vw" : "1.1vw"};
    font-weight: bold;
    font-style: italic;
  `;

  const LoanAmount = styled.div`
    color: black;
    font-size: ${isMobile ? "5.5vw" : "2vw"};
    font-weight: bold;
    font-style: italic;
  `;

  const SubTitle = styled.div`
    color: black;
    font-size: ${isMobile ? "2.5vw" : "1vw"};
    font-weight: bold;
    font-style: italic;
  `;

  const recordForm = (
    <FormGroup
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      row
    >
      <center
        style={{
          marginLeft: "100px",
          marginRight: "100px",
          overflowWrap: "break-word",
        }}
      >
        <Heading>CONGRATULATIONS!</Heading>
        <SubHeading>
          Because of your patronage and timely payments, <br />
          you are now eligible for a pre-approved personal loan of
        </SubHeading>
        <LoanAmount>P{loanAmount ? loanAmount.toLocaleString() : 0}</LoanAmount>
        <SubTitle>
          To get started, simply fill out the form below so that our loan <br />
          officer can contact you at your preferred time.
        </SubTitle>
      </center>

      <Col
        lg={7}
        className="mt-3"
        style={{ width: "60%", fontSize: isMobile ? "2.8vw" : "0.9vw" }}
      >
        Name
        <Input
          id="fullName"
          className="border border-right-0 border-dark rounded h-100"
        />
      </Col>

      <Col
        lg={7}
        className="mt-3"
        style={{ width: "60%", fontSize: isMobile ? "2.8vw" : "0.9vw" }}
      >
        Cell Number
        <Input
          id="contactNumber"
          className="border border-right-0 border-dark rounded h-100"
        />
      </Col>

      <Col
        lg={7}
        className="mt-3"
        style={{ width: "60%", fontSize: isMobile ? "2.8vw" : "0.9vw" }}
      >
        Preferred Date
        <Datepicker
          id="preferredCallingDate"
          minDate={minDate}
          className="border border-right-0 border-dark rounded h-100"
        />
      </Col>

      <Col
        lg={7}
        className="mt-3"
        style={{ width: "60%", fontSize: isMobile ? "2.8vw" : "0.9vw" }}
      >
        Preferred Time
        <Select
          id="preferredCallingTime"
          className="border border-right-0 border-dark rounded h-100"
          options={TIME_RANGE}
        />
      </Col>
      <div className="d-flex justify-content-center">
        <Button
          style={{
            width: "59%",
            color: "white",
            backgroundColor: "blue",
            fontWeight: "bold",
            fontSize: isMobile ? "2.8vw" : "0.9vw",
          }}
          className="mt-4 mb-3 rounded"
          type="submit"
          color="blue"
          disabled={isLoading}
        >
          {isLoading ? "Submitting" : "Register"}
        </Button>
      </div>
      { SHOW_LOGO && <img src={wheeltek_logo} alt="logo" style={{ width: "60%" }} /> }
    </FormGroup>
  );
  return recordForm;
};

export default ProspectResponse;
