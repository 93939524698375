import { UPDATE_PROSPECT_DETAILS, UPDATE_PROSPECT_DETAILS_SUCCESS,
    UPDATE_PROSPECT_DETAILS_FAIL, CLEAR_ACTION_STATUS } from "../actions/types"; 
   const initialState = {
       initiate : false,
       pending : false,
       loading : true,
       message : null,
       success : null,
       error : null
   }
   
   export default function(state=initialState,action){
       const{type, payload}=action;
       switch(type){
           case UPDATE_PROSPECT_DETAILS:
               return ({
                   ...state,
                   initiate : true,
                   pending : true
               })
           case UPDATE_PROSPECT_DETAILS_SUCCESS:
               return ({
                   ...state,
                   initiate : true,
                   pending : false,
                   loading : false,
                   success : true,
                   error : false,
                   message : payload
               })
           case UPDATE_PROSPECT_DETAILS_FAIL:
               return ({
                   ...state,
                   initiate : true,
                   pending : false,
                   loading : false,
                   success : false,
                   error : true,
                   message : payload
               })
               
           case CLEAR_ACTION_STATUS:
               return initialState;
               
           default:
               return state;
       }
   }