import axios from "axios";
import { GET_PROSPECT, GET_PROSPECT_FAIL, GET_PROSPECT_SUCCESS,
GET_CONTACT_HISTORY, GET_CONTACT_HISTORY_SUCCESS, 
GET_CONTACT_HISTORY_FAIL, UPDATE_CONTACT_RECORD, UPDATE_CONTACT_RECORD_SUCCESS,
UPDATE_CONTACT_RECORD_FAIL, CLEAR_ACTION_STATUS, UPDATE_PROSPECT_DETAILS, UPDATE_PROSPECT_DETAILS_FAIL, UPDATE_PROSPECT_DETAILS_SUCCESS, CLEAR_CURRENT_PROSPECT } from "./types";
import { BaseURL } from "../constant";
export const getProspect = (uuid, isPromo) =>
async dispatch => {
    dispatch({type : GET_PROSPECT});
    const config={
        headers:{
            "Content-Type":"application/json"
        }
    }
    var req = {};
    req.uuid = uuid;
    var res ={}
    try {
        let url;

        if (isPromo) {
            url = BaseURL+'/customers/promo/'+uuid
        } else {
            url = BaseURL+'/customers/details/'+uuid;
        }
        res = await axios.get(url);
        const { customer, ...rest} = res.data
        dispatch({
            type: GET_PROSPECT_SUCCESS,
            payload:{
                data : customer,
                ...rest
            }
        });
        //history.push("/getUserByName");
     
    } catch (err) {

       // const error = err.response;
        console.log(err)
        //dispatch(setAlert(err?.response?.data?.message,'danger'));
        dispatch({
            type: GET_PROSPECT_FAIL,
            payload: {
                error : err?.response?.data?.message
            }
        })
    }
}

export const getContactHistory = (uuid) => 
async dispatch =>{
    dispatch({type:GET_CONTACT_HISTORY});
    const config={
        headers:{
            "Content-Type":"application/json"
        }
    }
    var req = {};
    req.uuid = uuid;
    var res ={};
    try {
        res = await axios.get(BaseURL+'/contact-history?uuid=',{params : req},config);
        const { histories, ...rest} = res.data
        dispatch({
            type: GET_CONTACT_HISTORY_SUCCESS,
            payload:{
                list : histories,
                ...rest
            }
        });
        //history.push("/getUserByName");
     
    } catch (err) {

       // const error = err.response;
        console.log(err)
        //dispatch(setAlert(err?.response?.data?.message,'danger'));
        dispatch({
            type: GET_CONTACT_HISTORY_FAIL,
            payload: {
                error : err?.response?.data?.message
            }
        })
    }
}

export const updateProspectDetails = (req) => 
async dispatch =>{
    dispatch({type : UPDATE_PROSPECT_DETAILS});
    const config={
        headers:{
            "Content-Type":"application/json"
        }
    }

    const body = JSON.stringify(req);
    var res = {};
    try {
        const url = BaseURL+'/customers'
        
        res = await axios.post(url,body,config)
        dispatch({
            type : UPDATE_PROSPECT_DETAILS_SUCCESS,
            payload : "Details updated successfully"
        })
    } catch (err) {
        dispatch({
            type : UPDATE_PROSPECT_DETAILS_FAIL,
            payload : "Details update failed : "+err.response.data.message
        })
    }
}

export const updateContactRecord = (req, isPromo) =>
async dispatch =>{
    dispatch({type : UPDATE_CONTACT_RECORD});
    const config={
        headers:{
            "Content-Type":"application/json"
        }
    }

    const body = JSON.stringify(req);
    var res = {};
    try {
        let url;

        if (isPromo) {
            url = BaseURL+'/contact-history/promo'
        } else {
            url = BaseURL+'/contact-history'
        }
        
        res = await axios.post(url,body,config)
        dispatch({
            type : UPDATE_CONTACT_RECORD_SUCCESS,
            payload : "Contact record updated successfully"
        })
    } catch (err) {
        dispatch({
            type : UPDATE_CONTACT_RECORD_FAIL,
            payload : "Contact record update failed : "+err.response.data.message
        })
    }
}

// clean up methods
export const clearCurrentProspect = () =>
async dispatch =>{
    dispatch({type:CLEAR_CURRENT_PROSPECT});
}

export const clearActionStatus = () =>
async dispatch =>{
    dispatch({type:CLEAR_ACTION_STATUS});
}
