/* eslint-disable react/no-array-index-key */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import PropTypes from 'prop-types'
import AppContext from '../../utils';

const SalesStageDropdown = ({ data, handleChange }) => {
  const { tableState } = useContext(AppContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [active, setActive] = useState(tableState?.filter?.salesStageLabel || 'All sales stages');

  const handleSelectItem = (item) => {
    setActive(item);
    handleChange(item);
  };

  const renderItem = (item) => {
    return (
      <DropdownItem key={item} onClick={() => handleSelectItem(item)}>
        {item}
      </DropdownItem>
    );
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggleStyle caret color="x" className="p-0 border-0">
        {active === '' ? 'All sales stage' : active}
      </DropdownToggleStyle>
      <DropdownMenu right className="mt-2">
        <DropdownItem key="All sales stages" onClick={() => handleSelectItem('')}>
          All sales stages
        </DropdownItem>
        {data.map((item) => renderItem(item))}
      </DropdownMenu>
    </Dropdown>
  );
};

 const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    color: #263238;
    font-size: 20px;
    font-weight: 500;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
`;
SalesStageDropdown.propTypes = {
  handleChange:PropTypes.func,
  data: PropTypes.array.isRequired,
}
export default SalesStageDropdown;
