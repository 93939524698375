import React, { useEffect, useRef, lazy, useState } from 'react';
import styles from 'styled-components';
import { Formik, Field, Form } from 'formik';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Card, CardBody, Button, FormGroup, Label } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import wheeltek_logo from '../../img/Wheeltek Logo.png';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { Link, Redirect } from 'react-router-dom';
import Analytics from '../../components/web-services/firebase';
import store from "../../store.js";
import { loadUser } from "../../actions/auth";
import { ANALYTICS_ENABLED, SHOW_LOGO, TOASTR_ERROR_OPTIONS } from '../../constant';

export const LoginPage = ({auth,login,history}) => {
  const [isSubmitting,setIsSubmitting] = useState(false);
  const formikRef = useRef(null);
  useEffect(() => {
    if (auth?.error) {
      setIsSubmitting(false);
      toastr.error('Please enter a valid email id or password', auth.error, TOASTR_ERROR_OPTIONS);
    }

    if (auth?.isAuthenticated) {
      history.replace('/getProspectList');
    }
  }, [auth]);

  useEffect(() => {
    if (localStorage.token) {
      store.dispatch(loadUser());
    }

    // log login page visited event to firebase analytics
    ANALYTICS_ENABLED && Analytics.logEvent(Analytics.analytics, Analytics.EVENT_TYPE.loginPageVisited);

    // // set current screen to login page
    ANALYTICS_ENABLED && Analytics.logEvent(Analytics.analytics, 'screen_view', {
      firebase_screen: Analytics.EVENT_TYPE.loginPage,
      firebase_screen_class: Analytics.EVENT_TYPE.loginPage
    });
  }, [])

  const onSubmit = (values) => {
    // dispatch(userLogin(values, '/'));
        setIsSubmitting(true);
        setTimeout(()=>{
            setIsSubmitting(false);
        },2000)
        console.log(values);
        login(values.email,values.password,history);
    
  };

  

  return (
    <Container className="d-flex justify-content-center align-items-center flex-columns vh-100">
      <CardStyle>
        <CardBody>
          <div className="justify-content-center d-flex mt-3 mb-5">
            {SHOW_LOGO ? <img
              src={wheeltek_logo}
              alt="wheeltek"
              style={{ width: '80%' }}
            /> : "Login Page"}
          </div>
          <Formik
            innerRef={(instance) => {
              formikRef.current = instance;
            }}
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={onSubmit}
            validationSchema={loginSchema}
          >
            {({ touched, errors } ) => {
    
              return (
                <Form autoComplete="off" className="mx-3">
                  <FormGroupStyle>
                    <LoginLabel>Registered Email Address</LoginLabel>
                    <div>
                      <FieldStyle name="email" type="text" />
                      {touched.email && errors.email && <div className="text-danger">{errors.email}</div>}
                    </div>
                  </FormGroupStyle>
                  <FormGroupStyle>
                    <LoginLabel>Password</LoginLabel>
                    <div>
                      <FieldStyle name="password" type="password" />
                      {touched.password && errors.password && <div className="text-danger">{errors.password}</div>}
                    </div>
                  </FormGroupStyle>
                  <FormGroup className="mt-5 mb-4 d-flex justify-content-between">
                    <Button color="primary" type="submit" disabled={isSubmitting} className="w-50 h-50">
                      {isSubmitting ? 'Submitting' : 'Sign In'}
                    </Button>
                    <Link to="/forgot-password">
                  <Button
                    color="text"
                    style={{ color: '#4378FF' }}
                    type="button"
                  >
                    Forgot Password?
                  </Button>
                </Link>
                  </FormGroup>
                </Form>
              );
            }}
          </Formik>
        </CardBody>
      </CardStyle>
    </Container>
  );
};

const loginSchema = yup.object({
  email: yup.string().email('Please enter your email address').required('Please enter your email address'),
  password: yup.string().required('Please enter your password '),
});

const LoginLabel = styles(Label)`
  font-size: 19px;
  margin-bottom: 12px;
`;
const FieldStyle = styles(Field)`
  height: 60px;
  padding: 10px;
  width: 100%;
  border: none;
  background-color: #EDF2F7;
  margin-bottom: 5px;
`;
const CardStyle = styles(Card)`
  width: 500px;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
`;
const FormGroupStyle = styles(FormGroup)`
  text-align: left;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;
const Container = styles.div`
  background-color: #f5f9fb
`;

LoginPage.propTypes = {
    login : PropTypes.func.isRequired,
    auth: PropTypes.object,
}

const mapStateToProps = state =>({
    auth: state.auth   //whtever is required from reducer
})

export default connect(mapStateToProps,{login})(LoginPage);