import { DEFAULT_COUNTRY_CODE } from "../constant";
export const PHONE_VALIDATE = [
    {
      value: '+65',
      validate: {
        length: 8,
      },
    },
    {
      value: '+63',
      validate: {
        length: 10,
      },
    },
    {
      value: '+977',
      validate: {
        length: 10,
      },
    },
    {
      value: '+91',
      validate: {
        length: 10,
      },
    },
  ];

export const updateRecordValidate = (values, loanOffer, loanInterestRate, salesStage, submitParams) =>{
    const errors = {};
    if (submitParams && !values?.remarks) {
      errors.remarks = 'Please enter reason for approving/rejecting prospect';
    }

    if(!values?.salesStage){
        errors.salesStage = 'Please update the sales stage';
    }
    if(!values?.contactTag){
        errors.contactTag = 'Please select a corresponding contact tag'
    }

    if(!submitParams && values?.salesStage === 'not-started'){
        errors.salesStage = 'Please update the sales stage from Not started';
    }

    if (values?.salesStage === 'released' && values?.contactTag === "released" && !values?.loanNumber) {
      errors.loanNumber = 'Please specify a loan number'
    }

    if (salesStage === "Released" && !values?.remarks) {
      errors.remarks = 'Please provide a remark';
    }
    
    if(loanOffer!==0){
        if(Number(values?.loanAmount)>loanOffer){
            errors.loanAmount = 'Please limit the loan amount to PHP '+loanOffer.toLocaleString()
        }

        if(Number(values?.loanAmount) % 10000 != 0) {
          errors.loanAmount = 'Loan offer amount must be in multiples of 10,000'
        }

        if(Number(values?.loanAmount)<20000){
            errors.loanAmount = 'Minimum loan offer amount is PHP 20,000'
        }
    }

    if(Number(values?.loanAmount)<0){
        errors.loanAmount = 'Please enter a valid loan amount';
    }

    if(Number(values?.loanInterestRate)<0){
    errors.loanInterestRate = 'Please enter a valid interest rate';
    }

    if(Number(values?.loanInterestRate)!==loanInterestRate){
        errors.loanInterestRate = 'Please do not modify the interest rate';
    }
  
    if (values?.newPhoneNumber) {
      if (isNaN(values.newPhoneNumber)) errors.newPhoneNumber = 'Please enter valid phone number';
  
      const mappingValidation = PHONE_VALIDATE.filter((item) => item.value === DEFAULT_COUNTRY_CODE)[0];
      if (mappingValidation && values?.newPhoneNumber.length !== mappingValidation?.validate?.length) {
        errors.newPhoneNumber = 'Phone number cannot be of '+values?.newPhoneNumber.length+' digits';
      }
      
  }
  return errors;
  }