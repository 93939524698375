import { createContext } from "react";

const AppContext = createContext({
  tableState: {
    filter: {
      salesStage: "",
      salesStageLabel: "",
      search: "",
      salesAgent: 0,
      salesAgentName: "",
    },
    page: 1,
    sizePerPage: 15,
  },
  setTableState: (tableState) => {
    console.log(tableState);
  }
});

export default AppContext;
