/* eslint-disable react/no-array-index-key */
import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import PropTypes from "prop-types";
import AppContext from "../../utils/index";

const SalesAgentDropdown = ({ data, handleChange }) => {
  const { tableState } = useContext(AppContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [active, setActive] = useState(tableState?.filter?.salesAgentName || "All sales agents");

  const handleSelectItem = (item) => {
    setActive(item);
    if (item === "All sales agent") {
      handleChange(0);
    } else {
      handleChange(item);
    }
  };

  const renderItem = (item) => {
    return (
      <DropdownItem key={item} onClick={() => handleSelectItem(item)}>
        {item}
      </DropdownItem>
    );
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggleStyle caret color="x" className="p-0 border-0">
        {active ? active : "All sales agents"}
      </DropdownToggleStyle>
      <DropdownMenu className="mt-2">
        <DropdownItem
          key="All sales agent"
          onClick={() => handleSelectItem("All sales agent")}
        >
          All sales agents
        </DropdownItem>
        {data.map((item) => renderItem(item))}
      </DropdownMenu>
    </Dropdown>
  );
};

const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    color: #263238;
    font-size: 20px;
    font-weight: 500;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
`;
SalesAgentDropdown.propTypes = {
  handleChange: PropTypes.func,
  data: PropTypes.array.isRequired,
};
export default SalesAgentDropdown;
