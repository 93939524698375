import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  FormGroup,
  Button,
  Row,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";
import { useFormikContext } from "formik";
//import { Datepicker, Input, Select } from '../../form';
import { Datepicker, Input, Select } from "../form";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  SALES_STAGES_DD,
  SUCCESS_CONTACT_TAG,
  FAILURE_CONTACT_TAG,
  NOT_STARTED_CONTACT_TAG,
  IN_PROGRESS_CONTACT_TAG,
  LOAN_TENURE,
  CUSTOMER_INTEREST_LEVEL,
  TIME_RANGE,
  PROCESSING_FEES_FRACTION,
  RELEASED_CONTACT_TAG,
  SALES_STAGES_VALUES,
  CONTACT_TAG_VALUES,
} from "../../constant";
import { emiCalculator, processingFeesCalculator } from "../../utils/helpers";

const CustomerForm = ({
  auth,
  history,
  data,
  isReleased,
  setIsReleased,
  isContactFormEditable,
  showApprovalRequiredModal,
  setShowApprovalRequiredModal,
  setSubmitParams,
}) => {
  //const [showModal, setShowModal] = useState(false);
  const { values, setValues, dirty, isSubmitting } = useFormikContext();
  const [contactTagList, setContactTagList] = useState([]);

  useEffect(() => {
    if (data?.salesStage === "Released") {
      setIsReleased(true);
    } else {
      setIsReleased(false);
    }

    if (data?.recentContactAttempt?.contactTag === "Requires approval") {
      setContactTagList([
        {
          id: "requires-approval",
          value: "requires-approval",
          label: "Requires approval",
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (!isContactFormEditable) {
      return;
    }

    switch (values?.salesStage) {
      case "not-started":
        setContactTagList(NOT_STARTED_CONTACT_TAG);
        return;

      case "in-progress":
      case "interested":
        setContactTagList(IN_PROGRESS_CONTACT_TAG);
        return;

      case "approved":
        setContactTagList(SUCCESS_CONTACT_TAG);
        return;

      case "released":
        setContactTagList(RELEASED_CONTACT_TAG);
        return;

      case "dropped":
        setContactTagList(FAILURE_CONTACT_TAG);
        return;

      default:
        setContactTagList([]);
    }
  }, [values?.salesStage]);

  useEffect(() => {
    if (values?.loanAmount && values?.loanTenure && values?.loanInterestRate) {
      const loan = values?.loanAmount;
      const tenure = values?.loanTenure;
      const rate = values?.loanInterestRate;
      const emi = emiCalculator(Number(loan), Number(tenure), Number(rate));
      const processingFees = Math.ceil(PROCESSING_FEES_FRACTION * Number(loan));
      setValues({
        ...values,
        emi: emi,
        processingFees: processingFees,
      });
    } else {
      setValues({
        ...values,
        emi: 0,
        processingFees: 0,
      });
    }
  }, [values?.loanAmount, values?.loanTenure, values?.loanInterestRate]);

  const currency = "PHP";

  //   const confirmationModal = <ConfirmationModal showModal={showModal} handleSubmit={handleSubmit}
  //   closeModal={()=>{setShowModal(false); setConfirmChanges(false)}}
  //   confirmChanges={()=>{setShowModal(false); setConfirmChanges(true);}}/>

  // const handleSubmitTemp = (e) => {
  //   //setShowModal(true);
  //   const submitType = e.target.id;
  //   setValues({
  //     ...values,
  //     submitType: submitType,
  //   });
  // };

  const handleCancel = () => {
    setShowApprovalRequiredModal(false);
  };

  const handleReject = () => {
    setSubmitParams("reject");
  };

  const handleApprove = () => {
    setSubmitParams("approve");
  };

  const salesStageForm = (
    <FormGroup row className="mx-0">
      <div style={{ background: "#E3E6ED", width: "100%", height: 150 }}>
        <Row>
          <Col lg={6} className="pt-4 mt-1">
            <Select
              id="salesStage"
              label="Sales Stage (Mandatory)"
              options={SALES_STAGES_DD}
              placeholder={
                data?.recentContactAttempt?.salesStage ||
                data?.salesStage ||
                "Select"
              }
              disabled={isReleased || !isContactFormEditable}
            />
          </Col>
          {values.salesStage && (
            <Col lg={6} className="pt-4 mt-1">
              <Select
                id="contactTag"
                label="Contact Tag"
                options={contactTagList}
                placeholder="Select"
                disabled={isReleased || !isContactFormEditable}
              />
            </Col>
          )}
        </Row>
      </div>
    </FormGroup>
  );
  const recordForm = (
    <FormGroup row className="px-4 pt-4 pb-1">
      {(isReleased ||
        (values.salesStage === "released" &&
          values.contactTag === "released")) && (
        <Col lg={12} className="mt-3 px-2">
          <Input
            label="Loan Number (Mandatory)"
            id="loanNumber"
            placeholder="Type loan number"
            disabled={!isContactFormEditable}
          />
        </Col>
      )}

      <Col lg={6} className="mt-3 px-2">
        <Select
          id="customerInterestLevel"
          label="Customer Interest Level"
          options={CUSTOMER_INTEREST_LEVEL}
          placeholder={
            data?.recentContactAttempt?.customerInterestLevel
              ? CUSTOMER_INTEREST_LEVEL.find((element) => {
                  return (
                    element.value ===
                    data?.recentContactAttempt?.customerInterestLevel
                  );
                })?.label
              : "--"
          }
          disabled={isReleased || !isContactFormEditable}
        />
      </Col>

      <Col lg={6} className="mt-3">
        <Input
          label="Customer New Phone Number"
          id="newPhoneNumber"
          placeholder="Type phone number"
          disabled={isReleased || !isContactFormEditable}
        />
      </Col>

      <Col lg={6} className="mt-3">
        <Datepicker
          id="nextCallingDate"
          placeholder="Select Date"
          label="Next Preferred Calling Date"
          minDate={new Date()}
          disabled={isReleased || !isContactFormEditable}
        />
      </Col>

      <Col
        lg={6}
        className="mt-3"
        style={{ visibility: values.nextCallingDate ? "" : "hidden" }}
      >
        <Select
          id="nextCallingTime"
          label="Next Preferred Calling Time"
          options={TIME_RANGE}
          placeholder="Select time range"
          disabled={isReleased || !isContactFormEditable}
        />
      </Col>

      <Col lg={6} className="mt-3">
        <Input
          type="text"
          id="loanAmount"
          label="Discussed Loan Amount"
          inputIcon={<span>{currency}</span>}
          placeholder="Type amount"
          disabled={isReleased || !isContactFormEditable}
        />
      </Col>
      <Col lg={6} className="mt-3">
        <Select
          id="loanTenure"
          label="Discussed Loan tenure"
          options={LOAN_TENURE}
          disabled={isReleased || !isContactFormEditable}
        />
      </Col>
      <Col lg={6} className="mt-3">
        <Input
          label="Discussed Loan Interest Rate (% monthly)"
          id="loanInterestRate"
          disabled={true}
        />
      </Col>
      <Col lg={6} className="mt-3">
        <Input label="EMI" id="emi" disabled={true} />
      </Col>
      <Col lg={6} className="mt-3">
        <Input label="Processing Fees" id="processingFees" disabled={true} />
      </Col>
      <Col lg={12} className="mt-3">
        <Input
          type="textarea"
          id="remarks"
          label="Remarks"
          placeholder="Type here..."
          maxLength={1000}
          disabled={!isContactFormEditable && auth?.user?.role != "Manager"}
        />
      </Col>
      <div className="d-flex justify-content-between">
        <Button
          className="mt-4 mb-2"
          color="cancel"
          type="button"
          onClick={() => history.push("/getProspectList")}
        >
          Cancel
        </Button>
        {auth?.user?.role === "Manager" &&
        data?.status === "inactive-disqualified" ? (
          <div>
            <Button
              style={{ marginRight: "20px" }}
              className="mt-4 mb-2 btn btn-light border border-dark"
              type="submit"
              onClick={() => handleReject()}
            >
              Reject
            </Button>

            <Button
              className="mt-4 mb-2"
              color="primary"
              type="submit"
              onClick={() => handleApprove()}
            >
              Approve
            </Button>
          </div>
        ) : (
          <Button
            className="mt-4 mb-2"
            type="submit"
            color="primary"
            disabled={isSubmitting || !isContactFormEditable || !dirty}
            // onClick={()=>setShowModal(true)}
          >
            Submit
          </Button>
        )}
      </div>
    </FormGroup>
  );

  return (
    <>
      {/* <Col lg={10} className="mt-1">
      
      {confirmationModal}
      </Col> */}
      {salesStageForm}
      {recordForm}
      <Modal isOpen={showApprovalRequiredModal} centered size="md">
        <ModalBody className="p-4 flex justify-content-center">
          <center>
            <div className="bold" style={{ fontSize: "23px" }}>
              Contact your Manager
            </div>
            <span>
              Prospect is disqualfied due to
              {data?.recentContactAttempt?.remarks
                ? ` "${data?.recentContactAttempt?.remarks}"`
                : " not meeting certain criteria"}
              . Please contact your manager if you want to continue sales
              application for this prospect.
            </span>
          </center>
        </ModalBody>
        <ModalFooter className="text-center justify-content-center">
          <Button
            onClick={() => handleCancel()}
            color="primary"
            className="px-5 ml-3"
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withRouter(CustomerForm);
