import React, { useState, useRef, useEffect } from "react";
import styles from "styled-components";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Form } from "reactstrap";
import { Formik } from "formik";
import { ZendeskAPI } from "react-zendesk";
import PropTypes from "prop-types";
import * as yup from "yup";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { parsePhoneNumber } from "awesome-phonenumber";
import axios from "axios";

import promoImage from "../img/CDIpromo_582x700.jpg";
import ProspectResponse from "../components/tvs-components/prospectResponse";
import {
  getProspect,
  updateContactRecord,
  clearActionStatus,
} from "../actions/prospect";
import { CommsEngineURL, TOASTR_ERROR_OPTIONS } from "../constant";

const PromoPage = ({
  match,
  getProspect,
  prospect,
  updateContactRecord,
  clearActionStatus,
  updateRecord,
}) => {
  const formikRef = useRef(null);
  const elementRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loanAmount, setLoanAmount] = useState(null);
  const [zoom, setZoom] = useState(1);
  const uuid = match?.params?.uuid;
  const [fromCommsEngine, setfromCommsEngine] = useState(false);

  // define mobile as width less than 800
  const [isMobile, setIsMobile] = useState(false);

  const submit = async (values) => {
    if (!loanAmount) {
      return;
    }

    setLoading(true);

    // remove whitespaces
    const rawPhoneNumber = values.contactNumber.replace(/\s/g, "");

    const phoneNumberObject = parsePhoneNumber(rawPhoneNumber, "PH");
    const phoneNumber = phoneNumberObject.getNumber("significant");

    // trigger comms engine to populate customer's data in salestracker backend
    if (fromCommsEngine) {
      try {
        const body = JSON.stringify({ uuid, phoneNumber });
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        await axios.post(CommsEngineURL + "/processPromoMessage", body, config);
      } catch (err) {
        console.log(err);
      }
    }

    var req = {};

    req.customerUUID = uuid;
    req.customerPhoneNumber = phoneNumber;
    req.salesStage = "in-progress";
    req.contactTag = "need-follow-up";
    req.nextContactDate = values.preferredCallingDate;
    req.nextContactTime = values.preferredCallingTime;
    req.offeredLoanAmount = loanAmount;
    req.remarks = "[System] SMS - Customer submitted promo form.";
    await updateContactRecord(req, true);
  };

  useEffect(() => {
    // window.zE.hide();
    handleResize();
    ZendeskAPI("webWidget", "hide");
  });

  useEffect(() => {
    if (updateRecord?.initiate) {
      const { pending, success, error } = updateRecord;
      setLoading(pending || false);
      if (success) {
        formikRef.current?.resetForm();
        toastr.success(
          "",
          "Thank you for registering. Our staff has been notified of your interest and will follow up with you soon."
        );
      } else if (error) {
        toastr.error("Please use your registered phone number.", error, TOASTR_ERROR_OPTIONS);
      }
      clearActionStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRecord]);

  useEffect(() => {
    getProspect(uuid, true);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    if (elementRef.current) {
      const currentHeight = elementRef.current.offsetHeight;
      const currentWidth = elementRef.current.offsetWidth;
    
      // define mobile as width less than 800
      if (currentWidth < 800) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }

      const zoomFactor = currentHeight / 685;

      if (zoomFactor < 1 && !isMobile) {
        setZoom(zoomFactor);
      } else {
        setZoom(1);
      }
    }
  };

  useEffect(async () => {
    // return if prospect's data is still loading
    if (prospect.loading) {
      return;
    }

    if (prospect?.data?.data?.loanOfferAmount) {
      setLoanAmount(prospect?.data?.data?.loanOfferAmount);
    } else {
      // prospect data is not in salestracker, get from commsEngine
      try {
        const res = await axios.get(
          CommsEngineURL + "/getMessageLoanAmount/" + uuid
        );

        if (res.data?.loanAmount) {
          setLoanAmount(res.data.loanAmount);
          setfromCommsEngine(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [prospect.loading]);

  return loanAmount ? (
    <Div>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "60%",
          backgroundColor: "#EB0012",
        }}
      />
      <Card
        ref={elementRef}
        sx={{
          position: "relative",
          width: isMobile ? "85%" : "80%",
          height: "90%",
          display: "flex",
          flexDirection: "row",
          borderRadius: 1,
        }}
      >
        { !isMobile && <CardMedia
          style={{
            flex: 1,
            width: "50%",
            height: "100%",
            position: "relative",
          }}
          component="img"
          image={promoImage}
          alt="promoImage"
        /> }
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: isMobile ? "100%" : "50%",
            height: "100%",
            zoom: zoom,
            padding: 10,
            position: "relative",
            backgroundColor: "#F2F2FE",
          }}
        >
          <Formik
            innerRef={(instance) => {
              formikRef.current = instance;
            }}
            initialValues={{
              fullName: "",
              contactNumber: "",
              preferredCallingDate: "",
              preferredCallingTime: "",
            }}
            onSubmit={submit}
            validationSchema={yup.object({
              fullName: yup.string().required("Please enter your name"),
              contactNumber: yup
                .string()
                .required("Please enter your cell number"),
              preferredCallingDate: yup
                .string()
                .required("Please select your preferred date"),
              preferredCallingTime: yup
                .string()
                .required("Please select your preferred calling time"),
            })}
          >
            {({ handleSubmit, errors, ...rest }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <ProspectResponse loanAmount={loanAmount} isMobile={isMobile} isLoading={loading}/>
              </Form>
            )}
          </Formik>
        </Card>
      </Card>
    </Div>
  ) : null;
};

const Div = styles.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

PromoPage.propTypes = {
  getProspect: PropTypes.func.isRequired,
  prospect: PropTypes.object.isRequired,
  updateContactRecord: PropTypes.func.isRequired,
  clearActionStatus: PropTypes.func.isRequired,
  updateRecord: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  prospect: state.prospect,
  updateRecord: state.updateRecord,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getProspect,
  updateContactRecord,
  clearActionStatus,
})(withRouter(PromoPage));
