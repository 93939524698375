import axios from "axios";

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_FAIL,
  GET_EULA_DETAILS,
  GET_EULA_DETAILS_FAILED,
  POST_EULA_DETAILS,
  POST_EULA_DETAILS_FAILED,
  REQUEST_EMAIL_OTP_SUCCESS,
  REQUEST_EMAIL_OTP_FAIL,
  VERIFY_EMAIL_OTP_SUCCESS,
  VERIFY_EMAIL_OTP_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_AUTH_STATE,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { ANALYTICS_ENABLED, BaseURL } from "../constant";
import { version } from "react";
import jwt_decode from "jwt-decode";
import Analytics from "../components/web-services/firebase";

export const loadUser = () => async (dispatch) => {
  var decodedToken;
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    decodedToken = jwt_decode(localStorage.token);
    console.log(decodedToken);
    try {
      const res = await axios.get(BaseURL + "/user/" + decodedToken.id);
      dispatch({
        type: USER_LOADED,
        payload: res?.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
        payload: err?.response?.data?.message,
      });
    }
  }
};

//Login user
export const login = (email, password, history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(BaseURL + "/login", body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());

    // set current user id in analytics
    ANALYTICS_ENABLED && Analytics.setUserId(Analytics.analytics, res.data.userDetails.id);

    history.push("/getProspectList");
  } catch (err) {
    // const error = err.response;
    console.log(err);
    //dispatch(setAlert(err.response.data.message,'danger'));
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const getEulaDetail = (userId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  var req = {};
  req.userId = userId;
  try {
    const res = await axios.get(BaseURL + "/user-tc?userId=" + userId, config);
    dispatch({
      type: GET_EULA_DETAILS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_EULA_DETAILS_FAILED,
      payload: {},
    });
  }
};

export const postEulaDetail = (userId, version) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  var req = {};
  req.userId = userId;
  req.version = version;
  req.hasAccepted = true;
  const body = JSON.stringify(req);
  console.log(body);

  try {
    const res = await axios.post(BaseURL + "/user-tc", body, config);
    dispatch({
      type: POST_EULA_DETAILS,
      payload: res?.data,
    });
  } catch (error) {
    dispatch({
      type: POST_EULA_DETAILS_FAILED,
      payload: error?.response?.data?.message,
    });
  }
};

export const logout = (id, email, history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ id, email });
  try {
    const res = await axios.post(BaseURL + "/logout", body, config);
    dispatch({ type: LOGOUT });

    // set current user to null in analytics
    ANALYTICS_ENABLED && Analytics.setUserId(Analytics.analytics, null);

    history.push("/login");
  } catch (err) {
    // const error = err.response;
    console.log(err);
    //dispatch(setAlert(err.response.data.message,'danger'));
    dispatch({
      type: LOGOUT_FAIL,
    });
  }
};

//Request email otp
export const requestEmailOtp = (email, attemptNo) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, attemptNo });

  try {
    const res = await axios.post(
      BaseURL + "/user-otp/requestEmailOTP",
      body,
      config
    );
    dispatch({
      type: REQUEST_EMAIL_OTP_SUCCESS,
      payload: res?.data,
    });
  } catch (err) {
    // const error = err.response;
    console.log(err);
    //dispatch(setAlert(err.response.data.message,'danger'));
    dispatch({
      type: REQUEST_EMAIL_OTP_FAIL,
      payload: err?.response?.data?.message,
    });
  }
};

//Verify email otp
export const verifyEmailOtp = (email, otp) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, otp });

  try {
    const res = await axios.post(
      BaseURL + "/user-otp/verifyEmailOTP",
      body,
      config
    );

    if (res?.data?.statusCode === 200) {
      dispatch({
        type: VERIFY_EMAIL_OTP_SUCCESS,
        payload: res?.data,
      });
    } else {
      dispatch({
        type: VERIFY_EMAIL_OTP_FAIL,
        payload: res?.data?.message,
      });
    }
  } catch (err) {
    // const error = err.response;
    console.log(err);
    //dispatch(setAlert(err.response.data.message,'danger'));
    dispatch({
      type: VERIFY_EMAIL_OTP_FAIL,
      payload: err?.response?.data?.message,
    });
  }
};

//Reset user's password
export const resetPassword = (password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ password });

  try {
    const res = await axios.post(BaseURL + "/resetPassword", body, config);

    if (res?.data?.statusCode === 200) {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res?.data,
      });
    } else {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: res?.data?.message,
      });
    }
  } catch (err) {
    // const error = err.response;
    console.log(err);
    //dispatch(setAlert(err.response.data.message,'danger'));
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: err?.response?.data?.message,
    });
  }
};

export const resetAuthState = () => async (dispatch) => {
  dispatch({ type: RESET_AUTH_STATE });
};
