import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem } from 'reactstrap';
import styles from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { useLocation } from 'react-router';
import wheeltek_logo from '../../img/Wheeltek Logo.png'
import { SHOW_LOGO } from '../../constant';

const Header = ({logout,auth,history}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
//   const dispatch = useDispatch();
  const toggle = () => setDropdownOpen((prevState) => !prevState);


  const handleLogout = () => {
    const id = auth?.user?.id;
    const email = auth?.user?.email;
    logout(id,email,history);
  };
const location = useLocation();
  return (
    auth.isAuthenticated &&
    <Div>
      <ContainerDiv>
        <div className="d-flex justify-content-between mr-2 ml-2">
          <Link to="/getProspectList">
            { SHOW_LOGO && <img src={wheeltek_logo} alt="wheeltek" height="44" /> }
          </Link>
         
          <div>
            <DropdownStyle isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggleType>
                <div className="d-flex align-items-center">
                  <div className="header-avatar" />
                  {auth?.user?.firstName} {auth?.user?.lastName}
                  <div style={{marginLeft:'10px'}} className={!dropdownOpen ? 'pb-1' : ''}>
                    {!dropdownOpen ? (
                      <div className="ml-6 header-arrow-down" />
                    ) : (
                      <div className="ml-6 header-arrow-up" />
                    )}
                  </div>
                </div>
              </DropdownToggleType>
              <DropdownMenu container="body">
                <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </DropdownStyle>
          </div>
        </div>
        </ContainerDiv>
    </Div>
  );
};
const LinkStyle = styles(Link)`
  margin-right: 30px;
  color: #fff;
  textDecoration: none;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
}
`;
const Div = styles.div`
  background: #FE0101;
  padding: 5px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
`;
const ContainerDiv = styles.div`
max-width: 1500px;
margin: auto;
overflow: hidden;
padding: 0 2rem;`;

const DropdownStyle = styles(Dropdown)`
  font-weight: 400;
  &:hover, &:focus, &:visited {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: 0
  }
`;
const DropdownToggleType = styles(DropdownToggle)`
  background: transparent;
  border: none;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  &:hover, &:focus, &:visited, &:active {
    border: none !important;
    background: transparent !important;
    border: none;
    box-shadow: none  !important;
    outline: 0;
  }
`;

Header.propTypes = {
    logout : PropTypes.func.isRequired,
    auth : PropTypes.object.isRequired,
    }
    
    const mapStateToProps = state =>({
      auth: state.auth   //whtever is required from reducer
    })
    export default connect(mapStateToProps,{logout})(withRouter(Header));
