import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
// import { State } from 'redux/types';
import { Formik, FormikProps, Form, Field } from 'formik';
import * as yup from 'yup';
import { Button, FormGroup, Label, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import styles from 'styled-components';
import { useHistory, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Countdown } from '../../components/elements';
import { OtpInput } from '../../components/form';
import { RESEND_COUNTDOWN, TOASTR_ERROR_OPTIONS } from '../../constant';
import { requestEmailOtp, resetAuthState, verifyEmailOtp } from '../../actions/auth';

const ForgotPassword = ({auth, history, requestEmailOtp, verifyEmailOtp, resetAuthState}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [disabledResend, setDisabledResend] = useState(true);
  const [expired, setExpired] = useState(false);
  const [form, setForm] = useState({
    email: '',
  });
  const [attempts, setAttempts] = useState(1);
  const [isRequestEmailSubmitting, setIsRequestEmailSubmitting] = useState(false);
  const requestEmailformikRef = useRef(null);
  const otpFormRef = useRef(null);

  const handleFinishCountdown = () => {
    setDisabledResend(false);
  };

  const onEmailAddressSubmit = async (values) => {
    setIsRequestEmailSubmitting(true);

    requestEmailOtp(values.email, attempts).then(() => {
      setForm(values);
      setIsOpen(true);
      setIsRequestEmailSubmitting(false);
    }).catch((error) => {
      toastr.error('', error.message, TOASTR_ERROR_OPTIONS);
    });
  };

  const handleResend = async () => {
    // console.log(requestEmailformikRef.values);

    // return;

    setDisabledResend(true);

    setIsRequestEmailSubmitting(true);

    requestEmailOtp(requestEmailformikRef.current.values.email, attempts + 1).then(() => {
      setDisabledResend(true);
      setExpired(false);
      setIsRequestEmailSubmitting(false);
    }).catch((error) => {
      toastr.error('', error.message, TOASTR_ERROR_OPTIONS);
    });

    setAttempts(attempts => attempts + 1);
  };

  const handleCancel = () => {
    resetAuthState();
    history.push('/');
  }

  const onOtpSubmit = (values) => {
    verifyEmailOtp(values.email, values.otp);
  };

  useEffect(() => {
    if (auth?.error) {
      toastr.error('', auth?.error, TOASTR_ERROR_OPTIONS);
    }

    if (auth?.passwordResetToken) {
      history.push('/reset-password');
    }
  }, [auth]);

  const forgotPasswordSchema = yup.object({
    email: yup.string().email('Please enter a valid email address').required('Please enter your email address'),
  });

  const otpForm = (
    <Formik
      initialValues={{ otp: '', email: form.email }}
      onSubmit={onOtpSubmit}
      innerRef={(instance) => {
        otpFormRef.current = instance;
      }}
    >
      {({ values }) => {
        return (
          <Form>
            <FormLabel className="mb-4">
              Please enter the OTP code sent to your registered email: {form.email}
            </FormLabel>
            <FormLabel style={{ color: '#5A6168', fontWeight: 400 }}>Tips to receive OTP successfully:</FormLabel>
            {/* <ul style={{ color: '#5A6168', fontWeight: 400, fontSize: '16px' }}> */}
            <ul style={{ paddingLeft: '1.5em', listStyleType: 'disc' }}>
              <li>Make sure the email address your entered is correct. </li>
              <li>
                Make sure @TVSD.AI email domain is in your white list, check your email “Spam” or “Junk” folder if
                needed.
              </li>
            </ul>
            <OtpInput
              id="otp"
              numInputs={6}
              shouldAutoFocus
              inputStyle={{
                width: '50px',
                height: '70px',
                borderRadius: '8px',
                border: '1px solid #D9D9D9',
              }}
              containerStyle={{
                justifyContent: 'space-evenly',
              }}
            />
            <div className="text-center">
              <div className="mt-5 mb-3">
                <Button color="primary" className="w-75" type="submit" disabled={values.otp.length !== 6 || expired}>
                  Submit
                </Button>
              </div>
              <div>
                <Button color="outline-dark" className="w-75" disabled={disabledResend} onClick={handleResend}>
                  Resend OTP{' '}
                  {disabledResend && (
                    <>
                      (<Countdown seconds={RESEND_COUNTDOWN} onFinish={() => handleFinishCountdown()} />)
                    </>
                  )}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
 
  return (
    <>
      <Modal isOpen={isOpen} centered close>
        <ModalHeaderStyle toggle={() => setIsOpen(false)}>
          <FormHeader>OTP Verification</FormHeader>
        </ModalHeaderStyle>
        <ModalBody>{otpForm}</ModalBody>
      </Modal>
      <Formik
        innerRef={(instance) => {
          requestEmailformikRef.current = instance;
        }}
        initialValues={{
          email: '',
        }}
        onSubmit={onEmailAddressSubmit}
        validationSchema={forgotPasswordSchema}
      >
        {(props) => {
          const { touched, errors } = props;
          return (
            <Container className="d-flex justify-content-center align-items-center flex-columns vh-100">
              <CardStyle>
                <CardBody className="p-3">
                  <Form autoComplete="off" className="mx-3">
                    <FormHeader>Forgot password?</FormHeader>
                    <FormLabel>Please enter your registered email address to verify your account.</FormLabel>
                    <FormGroupStyle>
                      <FormLabel>Registered Email Address</FormLabel>
                      <div>
                        <FieldStyle name="email" type="text" />
                        {touched.email && errors.email && <div className="text-danger">{errors.email}</div>}
                      </div>
                    </FormGroupStyle>
                    <FormGroup className="mt-5 mb-2 d-flex justify-content-between">
                      <ButtonStyle className="w-40" color="cancel" type="button" onClick={handleCancel}>
                        Cancel
                      </ButtonStyle>
                      <ButtonStyle className="w-40" color="primary" type="submit" disabled={isRequestEmailSubmitting}>
                        Next
                      </ButtonStyle>
                    </FormGroup>
                  </Form>
                </CardBody>
              </CardStyle>
              {/* <Footer className="p-3 bg-white text-center">
                <img src={`${process.env.PUBLIC_URL}/images/powered_by.png`} alt="footer" />
              </Footer> */}
            </Container>
          );
        }}
      </Formik>
    </>
  );
};

const FormGroupStyle = styles(FormGroup)`
  text-align: left;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;
const FormLabel = styles(Label)`
  font-size: 19px;
  margin-bottom: 12px;
`;
const FieldStyle = styles(Field)`
  height: 60px;
  padding: 10px;
  width: 100%;
  border: none;
  background-color: #EDF2F7;
  margin-bottom: 5px;
`;

const CardStyle = styles(Card)`
  width: 500px;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  @media (max-width: 414px) {
    height: 100%;
    background: #fbf9fe
  }
`;
const Container = styles.div`
  background-color: #f5f9fb
`;

const ButtonStyle = styles(Button)`
  width: 200px;
  height: 50px;
  top: 542px;
`;

const ModalHeaderStyle = styles(ModalHeader)`
  border-bottom: 0;
  padding-bottom: 0;
`;

const FormHeader = styles.h1`
  font-size: 32px;
  font-family: Roboto Condensed;
  color: #5A6168; 
  font-weight: 400;
`;

const Footer = styles.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  @media (max-width: 414px) {
    bottom: 0;
  }
`;

ForgotPassword.propTypes = {
  requestEmailOtp : PropTypes.func.isRequired,
  verifyEmailOtp: PropTypes.func.isRequired,
  resetAuthState: PropTypes.func.isRequired,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth   //whtever is required from reducer
});

export default connect(mapStateToProps,{requestEmailOtp, verifyEmailOtp, resetAuthState})(withRouter(ForgotPassword));