import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styles from "styled-components";
import PrivateRoute from "./components/routers/PrivateRoute";
//Conatiners and Components
import login from "./containers/login/login";
import promoPage from "./containers/promoPage";
import ProspectList from "./containers/user_table/list";
import contactRecordForm from "./containers/contactRecordForm";
import ScrollToTop from "./containers/ScrollToTop";
//Redux
import { Provider } from "react-redux";
import store from "./store.js";
import ReduxToastr from "react-redux-toastr";
import AppContext from "./utils";
import forgotPassword from "./containers/login/forgotPassword";
import resetPassword from "./containers/login/reset-password";
import resetPasswordSuccess from "./containers/login/reset-password-success";

const App = () => {
  const [tableState, setTableState] = useState({
    filter: {
      salesStage: "",
      salesStageLabel: "",
      search: "",
      salesAgent: 0,
      salesAgentName: "",
    },
    page: 1,
    sizePerPage: 15,
  });

  return (
    <Provider store={store}>
      <Router>
        <AppContext.Provider
          value={{
            tableState,
            setTableState,
          }}
        >
          <ScrollToTop />
          <Main>
            <Switch>
              <Route exact path="/" component={login} />
              {/* <Route exact path='/pr' component={prospectResponseForm}/> */}
              <Route exact path="/login" component={login} />
              <Route exact path="/forgot-password" component={forgotPassword} />
              <Route exact path="/reset-password" component={resetPassword} />
              <Route exact path="/reset-password-success" component={resetPasswordSuccess} />
              <Route exact path="/promo/:uuid" component={promoPage} />
              <PrivateRoute
                exact
                path="/getProspectList"
                component={ProspectList}
              />
              <PrivateRoute
                exact
                path="/customers/:uuid"
                component={contactRecordForm}
              />
            </Switch>
            <ReduxToastr
              timeOut={3000}
              newestOnTop={false}
              preventDuplicates
              position="top-left"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick={true}
            />
          </Main>
        </AppContext.Provider>
      </Router>
    </Provider>
  );
};

const Main = styles.div`
  background-color: #f5f9fb;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh
`;
export default App;
