import axios from "axios";
import { PROSPECT_LIST, PROSPECT_LIST_SUCCESS, PROSPECT_LIST_FAIL, GET_USER_LIST, GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILED } from "./types";
import { BaseURL } from "../constant";
//export const getProspectList(search,salesStage,size,page) 

export const getProspectList = (tableState) => 
async dispatch =>{
    dispatch({
        type: PROSPECT_LIST
    });
    const config={
        headers:{
            "Content-Type":"application/json"
        }
    }
    var req = {};
    req.size = tableState.sizePerPage;
    req.page = tableState.page;
    req.search = tableState.filter.search;
    req.salesStage = tableState.filter.salesStage || '';
    req.agentId= tableState.filter.salesAgent;
    //console.log(body);
    var res ={}
    try {
        res = await axios.get(BaseURL+'/customers?',{params : req},config);
        const { customers, ...rest} = res.data
        dispatch({
            type: PROSPECT_LIST_SUCCESS,
            payload:{
                list : customers,
                ...rest
            }
        });
        //history.push("/getUserByName");
     
    } catch (err) {

       // const error = err.response;
        console.log(err)
        //dispatch(setAlert(err?.response?.data?.message,'danger'));
        dispatch({
            type: PROSPECT_LIST_FAIL,
            payload: {
                error : err?.response?.data?.message
            }
        })
    }
}

export const getUserList = () => 
async dispatch =>{
    dispatch({
        type: GET_USER_LIST
    });
    const config={
        headers:{
            "Content-Type":"application/json"
        }
    }
    //console.log(body);
    var res ={}
    try {
        res = await axios.get(BaseURL+'/user');
        dispatch({
            type: GET_USER_LIST_SUCCESS,
            payload:res.data
            
        });
        //history.push("/getUserByName");
     
    } catch (err) {

       // const error = err.response;
        console.log(err)
        //dispatch(setAlert(err?.response?.data?.message,'danger'));
        dispatch({
            type: GET_USER_LIST_FAILED,
            payload: {
                error : err?.response?.data?.message
            }
        })
    }

}
