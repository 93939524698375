import React from 'react';
import Loader from './Loader';
import PropTypes from 'prop-types'


const Boundary = ({ loading, children }) => {
  if (loading) return <Loader />;
  return children;
};

Boundary.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.element,
}
export default Boundary;
