/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Label, Input, FormGroup, InputGroup, InputGroupAddon, InputGroupText, CustomInput } from 'reactstrap';
import styles from 'styled-components';
import PropTypes from 'prop-types'



const TvsInput = ( {label,id,inputIcon,noShowError,formatType,placeholder,type,inline,labelStyle,
  groupStyle,maxLength,inputStyle,disabled,handleChange,className}) => {


  const { errors, touched, values, setValues } = useFormikContext();

  const inputTouched = (touched && touched[id]) || false;
  const inputErrors = (errors && errors[id]) || false;

  const currencyFormat = (value) => {
    const num = value.replace(/,/gi, '');
    const splitDecima = num.toString().split('.');
    const num2 = splitDecima[0].split(/(?=(?:\d{3})+$)/).join(',');
    const res = splitDecima.length > 1 ? `${num2}.${splitDecima[1]}` : num2;
    return res;
  };

  const onChange = (e) => {
    const target = e.target;
    let value;
    if (target.type === 'checkbox') {
      value = target.checked;
    } else if (formatType === 'currency') {
      value = currencyFormat(target.value);
    } else {
      value = target.value;
    }

    setValues({
      ...values,
      [id]: value,
    });

    if (handleChange) {
      setTimeout(() => {
        handleChange(value);
      }, 1000);
    }
  };
  const [touch, setTouch] = useState(false);

  return (
    <FormGroup className={inline ? 'd-flex' : ''} style={groupStyle || {}}>
      {type !== 'checkbox' && label && <Label style={labelStyle}>{label}</Label>}
      <InputGroup style={inputStyle}>
        {type && type !== 'text' && type !== 'textarea' && type !== 'date' && type!== 'password'? (
          <CustomInput
            autocompleted="off"
            placeholder={placeholder}
            type={type}
            id={id}
            label={type === 'checkbox' ? label : ''}
            onChange={(e) => onChange(e)}
          />
        ) : (
          <>
            <Input
              placeholder={placeholder}
              id={id}
              type={type}
              maxLength={maxLength}
              onChange={(e) => onChange(e)}
              value={values[id]}
              disabled={disabled}
              autocompleted="off"
              onBlur={(e) => setTouch(true)}
              className = {className}
            />
          </>
        )}
        {inputIcon && (
          <InputGroupAddon addonType='prepend' className="text-centre">
            <InputText><div className="text-centre text-large" style={{height: '36px'}}>{inputIcon}</div></InputText>
          </InputGroupAddon>
        )}
      </InputGroup>
      {maxLength && (
        <div className="form-text text-small text-bold">
          {maxLength - (values[id]?.length|| 0)} {maxLength - (values[id]?.length|| 0) === 1 ? 'character' : 'characters'}{' '}
          remaining.
        </div>
      )}
      {(touched[id] || touch) && inputErrors && !noShowError && <div className="form-text text-danger text-small">{inputErrors}</div>}
    </FormGroup>
  );
};

const InputText = styles(InputGroupText)`
  top: 100px;
  bottom: 0;
  border: 1px solid #ced4da;
  right: 10px;
  font-size: 50;
`;

TvsInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  noShowError: PropTypes.bool,
  label: PropTypes.string,
  inputIcon: PropTypes.element,
  formatType:PropTypes.string,
  type: PropTypes.oneOf(['text','textarea' ,'date','password','plaintext']),
  inline: PropTypes.bool,
  labelStyle: PropTypes.any,
  groupStyle: PropTypes.any,
  maxLength: PropTypes.number,
  inputStyle: PropTypes.any,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  className : PropTypes.string,
};
export default TvsInput;
