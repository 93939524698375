import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';


import styled from 'styled-components';
import PropTypes from 'prop-types'


const Table = ({ data, totalSize, sizePerPage, columns, page, onTableChange, keyField, loading }) => {
  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: <div className="table-arrow-left" />,
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    totalSize,
    sizePerPage,
  };

  const rowStyle = (row, rowIndex) => {
    const style = {
      color: '#5A6168',
    };
    if (rowIndex % 2 === 0) {
      return {
        background: 'rgba(42, 42, 62, 0.04)',
        ...style,
      };
    }
    return {
      background: '#fff',
      ...style,
    };
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => {
        // eslint-disable-next-line no-param-reassign
        paginationProps.dataSize = totalSize;
        // eslint-disable-next-line no-param-reassign
        paginationProps.page = page;

        return (
          <ToolkitProvider keyField={keyField || 'id'} columns={columns} data={data} search>
            {(toolkitprops) => (
              <>
                <BootstrapTable
                  bordered={false}
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                  remote={{ filter: true, sort: true, pagination: true }}
                  onTableChange={onTableChange}
                  noDataIndication={loading ? 'Loading' : 'No result'}
                  rowStyle={rowStyle}
                  loading={loading}
                  overlay={overlayFactory({ background: '#fbfbfc' })}
                />

                <div className="d-flex justify-content-center table-pagination my-5">
                  <PaginationListStandalone {...paginationProps} className="d-inline mr-auto" />
                </div>
              </>
            )}
          </ToolkitProvider>
        );
      }}
    </PaginationProvider>
  );
};

Table.propTypes = {
  data : PropTypes.array.isRequired, 
  totalSize : PropTypes.number.isRequired,
  sizePerPage : PropTypes.number.isRequired, 
  columns : PropTypes.array.isRequired, 
  page : PropTypes.number.isRequired, 
  onTableChange : PropTypes.func.isRequired,
  keyField : PropTypes.string, 
  loading : PropTypes.bool,
}
export default Table;

export const sortCaret = (column, sort, order) => {
  const IStyled = styled.i`
    display: inline-block;
    margin-left: 10px;
    &.unsort {
      border: solid #e5e5e5;
      border-width: 0 2px 2px 0;
      padding: 3px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      margin-bottom: 2px;
    }
    &.up {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-bottom: 5px solid #5a6168;
      margin-bottom: 2px;
    }
    &.down {
      margin-bottom: 2px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-top: 5px solid #5a6168;
    }
  `;
  if (!sort || sort.s !== column.dataField) return <IStyled className="arrow unsort" />;
  if (sort.o === 'asc') return <IStyled className="arrow up" />;
  if (sort.o === 'desc') return <IStyled className="arrow down" />;
  return null;
};
