/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  CardBody,
  Card,
  Container,
  Form,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik } from "formik";
import {
  LatestContactRecordFrom,
  ContactDetails,
} from "../components/tvs-components";
import { toastr } from "react-redux-toastr";
import {
  getProspect,
  clearCurrentProspect,
  updateContactRecord,
  clearActionStatus,
  updateProspectDetails,
  getContactHistory,
} from "../actions/prospect";
import PropTypes from "prop-types";
import * as yup from "yup";
import { connect } from "react-redux";
import styles from "styled-components";
import { withRouter } from "react-router";
import { Breadcrumb } from "../components/elements";
import ContactHistoryTable from "./contactHistoryTable/list";
import { updateRecordValidate } from "../utils/validate";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import {
  SALES_STAGES_VALUES,
  CONTACT_TAG_VALUES,
  PROCESSING_FEES_FRACTION,
  TOASTR_ERROR_OPTIONS,
  ANALYTICS_ENABLED,
} from "../constant";
import { emiCalculator } from "../utils/helpers";
import Analytics from "../components/web-services/firebase";
import setAuthToken from "../utils/setAuthToken";
import store from "../store";
import { loadUser } from "../actions/auth";

const ContactRecordForm = ({
  match,
  history,
  getProspect,
  clearCurrentProspect,
  prospect,
  updateContactRecord,
  updateProspectDetails,
  clearActionStatus,
  updateRecord,
  updateProspect,
  auth,
  contactHistory,
  getContactHistory,
}) => {
  const contactDetailsFormikRef = useRef(null);
  const contactFormFormikRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [contactHistoryLoading, setContactHistoryLoading] = useState(false);
  const [editableAddress, setEditableAddress] = useState(false);
  const toggleAddress = () => setEditableAddress(!editableAddress);
  const [data, setData] = useState({});
  const [isReleased, setIsReleased] = useState(false);
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [isContactFormEditable, setIsContactFormEditable] = useState(true);
  const [showApprovalRequiredModal, setShowApprovalRequiredModal] =
    useState(false);
  const [submitParams, setSubmitParams] = useState("");
  const [contactHistoryData, setContactHistoryData] = useState([]);

  //const [showModal, setShowModal] = useState(false);
  //const [confirmChanges, setConfirmChanges] = useState(false);

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      store.dispatch(loadUser());
    }
    
    // log record result page visited event to firebase analytics
    ANALYTICS_ENABLED && Analytics.logEvent(
      Analytics.analytics,
      Analytics.EVENT_TYPE.recordResultPageVisited,
      {
        user_id: auth?.user?.id,
        role: auth?.user?.role,
      }
    );

    // set current screen to record result page
    ANALYTICS_ENABLED && Analytics.logEvent(Analytics.analytics, "screen_view", {
      firebase_screen: Analytics.EVENT_TYPE.recordResultPage,
      firebase_screen_class: Analytics.EVENT_TYPE.recordResultPage,
      user_id: auth?.user?.id,
      role: auth?.user?.role,
    });
  }, []);

  useEffect(() => {
    getProspect(match?.params?.uuid, false);
    getContactHistory(match?.params?.uuid);

    return function cleanup() {
      clearCurrentProspect();
    };
  }, [match?.params?.uuid]);

  useEffect(() => {
    setContactHistoryLoading(contactHistory?.pending || false);
    setContactHistoryData(contactHistory?.data || []);
  }, [contactHistory]);

  useEffect(() => {
    setData(prospect?.data?.data);
    if (prospect?.data?.data?.status === "inactive-disqualified") {
      setIsContactFormEditable(false);

      if (auth?.user?.role && auth?.user?.role != "Manager") {
        setShowApprovalRequiredModal(true);
      }
    } else {
      setIsContactFormEditable(true);
      setShowApprovalRequiredModal(false);
    }
  }, [prospect?.data?.data]);

  useEffect(() => {
    if (updateRecord?.initiate) {
      const { pending, success, error, message } = updateRecord;
      setLoading(pending || false);
      if (success) {
        clearActionStatus();
        toastr.success("", message);
        history.push("/getProspectList");
      } else if (error) {
        toastr.error("Can not save data", error, TOASTR_ERROR_OPTIONS);
        clearActionStatus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRecord]);

  useEffect(() => {
    if (updateProspect?.initiate) {
      const { pending, success, error, message } = updateProspect;
      if (success) {
        const newData = {
          ...data,
          address: contactDetailsFormikRef.current.values.address,
        };
        setData(newData);
        clearActionStatus();
        toggleAddress();
        toastr.success("", message);
      } else if (error) {
        toastr.error("Can not save data", error, TOASTR_ERROR_OPTIONS);
        clearActionStatus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProspect]);

  const submit = async (values) => {
    if (
      !isReleased &&
      values.salesStage === "released" &&
      values.contactTag === "released" &&
      !showSubmissionModal
    ) {
      setShowSubmissionModal(true);
      return;
    } else {
      setShowSubmissionModal(false);
    }

    var req = {};
    req.customerUUID = data?.uuid;
    req.salesStage = values?.salesStage;
    req.contactTag = values?.contactTag;
    req.contactChannel = data?.contactChannel || "SMS";
    req.offeredLoanAmount = values?.loanAmount;
    req.offeredLoanInterestRate = values?.loanInterestRate;
    req.offeredLoanTenure = values?.loanTenure;
    req.customerInterestLevel =
      values?.customerInterestLevel ||
      data?.recentContactAttempt?.customerInterestLevel;
    req.nextContactDate = values?.nextCallingDate;
    req.nextContactTime = values?.nextCallingTime;
    req.customerNewPhone = values?.newPhoneNumber;
    req.emi = values?.emi;
    req.loanProcessingAmount = values?.processingFees;
    req.remarks = values?.remarks;
    req.salesAgentId = auth?.user?.id;
    req.loanNumber = values?.loanNumber;

    if (submitParams === "approve") {
      const lastContactHistory = contactHistoryData.find((contactHistory) => {
        return contactHistory.contactTag != "Requires approval";
      });

      req.contactTag = lastContactHistory
        ? CONTACT_TAG_VALUES[lastContactHistory.contactTag]
        : "not-started";
    } else if (submitParams === "reject") {
      req.salesStage = "dropped";
      req.contactTag = "not-eligible";
    }

    console.log(req);
    await updateContactRecord(req, false);
  };

  const handleSaveAddress = async (values) => {
    var req = {};
    req.uuid = data?.uuid;
    req.extra = {
      customerAddress: values.address,
    };
    await updateProspectDetails(req);
  };

  const customValidate = (values) => {
    const errors = updateRecordValidate(
      values,
      Math.ceil(
        data?.loanOfferAmount ||
          data?.recentContactAttempt?.loanOfferAmount ||
          0
      ),
      data?.loanInterestRate ||
        data?.recentContactAttempt?.loanInterestRate ||
        0,
      data?.salesStage,
      submitParams
    );
    return errors;
  };

  const handleCancel = () => {
    setShowSubmissionModal(false);
  };

  return (
    prospect.data && (
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <Header />
        <Breadcrumb
          data={[
            {
              label: "Prospect List",
              link: "/getProspectList",
            },
            {
              label: "Contact Record",
            },
          ]}
        />
        <Container style={{ paddingBottom: "100px", paddingLeft: "200px" }}>
          <div className="pt-4 pb-2 ">
            <h1 className="mb-2">Record Contact Result</h1>
          </div>
          <div>
            <Row>
              <Col lg={4}>
                <Card className="rounded-0">
                  <CardBody className="p-0 rounded-0">
                    <Formik
                      innerRef={(instance) => {
                        contactDetailsFormikRef.current = instance;
                      }}
                      initialValues={{
                        address: "",
                      }}
                      enableReinitialize
                      validationSchema={yup.object({
                        address: yup.string().required("Required"),
                      })}
                      onSubmit={handleSaveAddress}
                    >
                      {({ handleSubmit, errors, ...rest }) => (
                        <Form onSubmit={handleSubmit} autoComplete="off">
                          <ContactDetails
                            toggleAddress={toggleAddress}
                            editableAddress={editableAddress}
                            setEditableAddress={setEditableAddress}
                            data={data}
                          />
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={7}>
                <Card className="rounded-0">
                  <CardBody className="p-0 rounded-0">
                    <Formik
                      innerRef={(instance) => {
                        contactFormFormikRef.current = instance;
                      }}
                      initialValues={{
                        salesStage: SALES_STAGES_VALUES[data?.salesStage],
                        contactTag:
                          CONTACT_TAG_VALUES[
                            data?.recentContactAttempt?.contactTag
                          ],
                        loanAmount: Math.ceil(
                          data?.recentContactAttempt?.loanOfferAmount ||
                            data?.loanOfferAmount ||
                            0
                        ),
                        loanTenure:
                          data?.recentContactAttempt?.loanTenure ||
                          data?.loanTenure ||
                          0,
                        loanInterestRate:
                          data?.recentContactAttempt?.loanInterestRate ||
                          data?.loanInterestRate ||
                          0,
                        newPhoneNumber:
                          data?.recentContactAttempt?.customerNewPhoneNumber ||
                          data?.phoneNumber ||
                          "",
                        loanNumber:
                          data?.recentContactAttempt?.loanNumber || "",
                        emi: emiCalculator(
                          Number(
                            Math.ceil(
                              data?.recentContactAttempt?.loanOfferAmount ||
                                data?.loanOfferAmount ||
                                0
                            )
                          ),
                          Number(
                            data?.recentContactAttempt?.loanTenure ||
                              data?.loanTenure ||
                              0
                          ),
                          Number(
                            data?.recentContactAttempt?.loanInterestRate ||
                              data?.loanInterestRate ||
                              0
                          )
                        ),
                        processingFees: Math.ceil(
                          PROCESSING_FEES_FRACTION *
                            Number(
                              Math.ceil(
                                data?.recentContactAttempt?.loanOfferAmount ||
                                  data?.loanOfferAmount ||
                                  0
                              )
                            )
                        ),
                        remarks: "",
                      }}
                      enableReinitialize
                      onSubmit={submit}
                      validate={customValidate}
                    >
                      {({ handleSubmit, errors, ...rest }) => (
                        <Form onSubmit={handleSubmit} autoComplete="off">
                          <LatestContactRecordFrom
                            auth={auth}
                            data={data}
                            isReleased={isReleased}
                            setIsReleased={setIsReleased}
                            isContactFormEditable={isContactFormEditable}
                            showApprovalRequiredModal={
                              showApprovalRequiredModal
                            }
                            setShowApprovalRequiredModal={
                              setShowApprovalRequiredModal
                            }
                            setSubmitParams={setSubmitParams}
                          />
                          <Modal
                            isOpen={showSubmissionModal}
                            centered
                            size="md"
                          >
                            <ModalBody className="p-4 flex justify-content-center">
                              <center>
                                <span>
                                  Sales stage can no longer be edited once
                                  submitted
                                </span>
                                <br />
                                <span>Confirm submission?</span>
                              </center>
                            </ModalBody>
                            <ModalFooter className="text-center justify-content-center">
                              <Button
                                onClick={() => handleCancel()}
                                color="cancel"
                                className="px-5 mr-3"
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={() => handleSubmit()}
                                color="primary"
                                className="px-5 ml-3"
                              >
                                Submit
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="mt-2">
            <ContactHistoryTable
              contactHistoryLoading={contactHistoryLoading}
              contactHistoryData={contactHistoryData}
            />
          </div>
        </Container>
        <Footer />
      </div>
    )
  );
};

const H5 = styles.h5`
  opacity: 0.8;
`;

ContactRecordForm.propTypes = {
  getProspect: PropTypes.func.isRequired,
  clearCurrentProspect: PropTypes.func.isRequired,
  prospect: PropTypes.object.isRequired,
  updateContactRecord: PropTypes.func.isRequired,
  updateProspectDetails: PropTypes.func.isRequired,
  clearActionStatus: PropTypes.func.isRequired,
  updateRecord: PropTypes.object.isRequired,
  updateProspect: PropTypes.object.isRequired,
  contactHistory: PropTypes.object.isRequired,
  getContactHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  prospect: state.prospect,
  updateRecord: state.updateRecord,
  updateProspect: state.updateProspect,
  auth: state.auth,
  contactHistory: state.contactHistory,
});

export default connect(mapStateToProps, {
  getProspect,
  clearCurrentProspect,
  updateContactRecord,
  updateProspectDetails,
  clearActionStatus,
  getContactHistory,
})(withRouter(ContactRecordForm));
