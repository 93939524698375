import React from 'react';
import { ReactComponent as Logo } from '../../img/Powered by TVS Digital.svg';

const Footer = () => {
 return(<div
    style={{
      position: 'fixed',
      bottom: 0,
      height: '30px',
      background: '#fff',
      borderTop: '1px solid #DAE0F7',
      width: '100%',
      padding: '20px 0',
      zIndex: 10,
    }}
  >
    <div className="text-center d-flex justify-content-center align-items-center h-100">
      <Logo/>
    </div>
  </div>)
};

export default Footer;