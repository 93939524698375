import { PROCESSING_FEES_FRACTION } from "../constant";
import moment from "moment";

export const currencyFormat = (num) => {
  if (num === undefined || num === null || Number.isNaN(Number(num))) {
    return "0.00";
  }

  return Number(num).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const emiCalculator = (loan, tenure, rateOfInterest = 2) => {
  if (loan === 0 || tenure === 0) {
    return 0;
  }
  const loanAmt = Number(loan);
  const loanTenure = Number(tenure);
  const rate = Number(rateOfInterest * tenure) / 100;
  const x = 1 + rate;
  const ppd = 500 * tenure;
  const amount = loanAmt * x + ppd;
  const emi = amount / loanTenure;
  return Math.ceil(emi);
};

export const processingFeesCalculator = (loan) => {
  return Math.ceil(PROCESSING_FEES_FRACTION * Number(loan));
};

export const generateLeadAge = (row) => {
  const createdAt = moment(row?.createdAt);
  if (
    row?.recentContactAttempt?.salesStage === "Dropped" ||
    row?.recentContactAttempt?.salesStage === "Released"
  ) {
    const recentContactAttempt = moment(row?.recentContactAttempt?.recordDate);

    return getFormattedDiffBetweenMoments(recentContactAttempt, createdAt);
  }

  const now = moment();

  return getFormattedDiffBetweenMoments(now, createdAt);
};

export const getFormattedDiffBetweenMoments = (moment1, moment2) => {
  const diffInDays = Math.abs(moment1.diff(moment2, "days"));

  if (diffInDays < 1) {
    const diffInHours = Math.abs(moment1.diff(moment2, "hours"));
    if (diffInHours < 1) {
      const diffInMinutes = Math.abs(moment1.diff(moment2, "minutes"));
      return diffInMinutes > 1 ? `${diffInMinutes} minutes` : "a minute";
    }
    return diffInHours > 1 ? `${diffInHours} hours` : "an hour";
  }
  return diffInDays > 1 ? `${diffInDays} days` : "a day";
};
