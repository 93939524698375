export const USER_LOADED= "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_EULA_DETAILS = "GET_EULA_DETAILS";
export const GET_EULA_DETAILS_FAILED = "GET_EULA_DETAILS_FAILED";
export const POST_EULA_DETAILS = "POST_EULA_DETAILS";
export const POST_EULA_DETAILS_FAILED = "POST_EULA_DETAILS_FAILED";
export const PROSPECT_LIST = "PROSPECT_LIST";
export const PROSPECT_LIST_SUCCESS = "PROSPECT_LIST_SUCCESS";
export const PROSPECT_LIST_FAIL = "PROSPECT_LIST_FAIL";
export const GET_PROSPECT = "GET_PROSPECT";
export const GET_PROSPECT_SUCCESS = "GET_PROSPECT_SUCCESS";
export const GET_PROSPECT_FAIL = "GET_PROSPECT_FAIL";
export const CLEAR_CURRENT_PROSPECT = "CLEAR_CURRENT_PROSPECT";
export const GET_CONTACT_HISTORY = "GET_CONTACT_HISTORY";
export const GET_CONTACT_HISTORY_SUCCESS = "GET_CONTACT_HISTORY_SUCCESS";
export const GET_CONTACT_HISTORY_FAIL = "GET_CONTACT_HISTORY_FAIL";
export const UPDATE_CONTACT_RECORD = "UPDATE_CONTACT_RECORD";
export const UPDATE_CONTACT_RECORD_SUCCESS = "UPDATE_CONTACT_RECORD_SUCCESS";
export const UPDATE_CONTACT_RECORD_FAIL = "UPDATE_CONTACT_RECORD_FAIL";
export const CLEAR_ACTION_STATUS = "CLEAR_ACTION_STATUS";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILED = "GET_USER_LIST_FAILED";
export const UPDATE_PROSPECT_DETAILS = "UPDATE_PROSPECT_DETAILS";
export const UPDATE_PROSPECT_DETAILS_SUCCESS = "UPDATE_PROSPECT_DETAILS_SUCCESS";
export const UPDATE_PROSPECT_DETAILS_FAIL = "UPDATE_PROSPECT_DETAILS_FAIL";
export const REQUEST_EMAIL_OTP_SUCCESS = "REQUEST_EMAIL_OTP_SUCCESS";
export const REQUEST_EMAIL_OTP_FAIL = "REQUEST_EMAIL_OTP_FAIL";
export const VERIFY_EMAIL_OTP_SUCCESS = "VERIFY_EMAIL_OTP_SUCCESS";
export const VERIFY_EMAIL_OTP_FAIL = "VERIFY_EMAIL_OTP_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";