import React from 'react';
import { Button, Card, CardBody, FormGroup, Label } from 'reactstrap';
import styles from 'styled-components';

const ResetPasswordSuccess = ({history}) => {
  const redirectToLogin = () => {
    history.push('/login');
  };

  return (
    <Container className="d-flex justify-content-center align-items-center flex-columns vh-100">
      <CardStyle>
        <CardBody className="p-3">
          <img
            className="mt-4 mb-4 mx-auto d-block"
            src={`${process.env.PUBLIC_URL}/images/lock.png`}
            alt="lock"
            width="80"
            height="80"
          />
          <FormHeader className="d-flex justify-content-center">Password is successfully set.</FormHeader>
          <FormLabel className="d-flex justify-content-center">Please use your new password to login.</FormLabel>

          <FormGroup className="mt-5 mb-3 d-flex justify-content-center">
            <ButtonStyle className="w-40" color="primary" type="submit" onClick={redirectToLogin}>
              Login
            </ButtonStyle>
          </FormGroup>
        </CardBody>
      </CardStyle>
      {/* <Footer className="p-3 bg-white text-center">
        <img src={`${process.env.PUBLIC_URL}/images/powered_by.png`} alt="footer" />
      </Footer> */}
    </Container>
  );
};

const FormLabel = styles(Label)`
  font-size: 19px;
`;

const FormHeader = styles.h1`
  font-size: 32px;
  font-family: Roboto Condensed;
  color: #5A6168; 
  font-weight: 400;
`;

const ButtonStyle = styles(Button)`
  width: 200px;
  height: 50px;
`;

const CardStyle = styles(Card)`
  width: 512px;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  @media (max-width: 414px) {
    height: 100%;
    background: #fbf9fe
  }
`;
const Container = styles.div`
  background-color: #f5f9fb
`;

const Footer = styles.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  @media (max-width: 414px) {
    bottom: 0;
  }
`;

export default ResetPasswordSuccess;
