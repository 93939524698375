import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import { Button } from "reactstrap";
import {
  currencyFormat,
  generateLeadAge,
  getFormattedDiffBetweenMoments,
} from "../../utils/helpers";
import { CUSTOMER_INTEREST_LEVEL, LEAD_CATEGORY_COLOR } from "../../constant";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    textAlign: "center",
  },
}));

export const getProspectListingCols = () => [
  {
    text: "Agent Name",
    formatter: (cell, row) => (
      <span>
        {row?.recentContactAttempt?.salesAgent?.firstName
          ? row?.recentContactAttempt?.salesAgent?.firstName +
            " " +
            row?.recentContactAttempt?.salesAgent?.lastName
          : row?.salesAgent?.firstName + " " + row?.salesAgent?.lastName}
      </span>
    ),
  },
  {
    text: "Lead Stage",
    formatter: (cell, row) => (
      <span>{row?.recentContactAttempt?.salesStage || row?.salesStage}</span>
    ),
    //sort: true,
    //order: ['asc','desc'],
    //sortCaret: (order, column) => sortCaret(column, sort, order),
    //headerSortingStyle
  },
  {
    text: "Lead Age",
    formatter: (cell, row) => {
      const momentCreatedAt = moment(row?.createdAt);
      return (
        <BootstrapTooltip
          title={
            <span
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {momentCreatedAt.format("DD/MM/YYYY")}
            </span>
          }
        >
          <span>{generateLeadAge(row)}</span>
        </BootstrapTooltip>
      );
    },
  },
  {
    text: "Contact Stage",
    formatter: (cell, row) => (
      <span>{row?.recentContactAttempt?.contactTag || "Not started"}</span>
    ),
  },
  {
    text: "Days Since Contact",
    formatter: (cell, row) => {
      const momentTimestampOfLastContact = moment(row?.timestampOfLastContact);
      return (
        <BootstrapTooltip
          title={
            <span
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {momentTimestampOfLastContact.format("DD/MM/YYYY")}
            </span>
          }
        >
          <span>
            {getFormattedDiffBetweenMoments(
              moment(),
              momentTimestampOfLastContact
            )}
          </span>
        </BootstrapTooltip>
      );
    },
  },
  {
    text: "Preferred Calling Date",
    formatter: (cell, row) => (
      <span style={{ whiteSpace: "pre-line" }}>
        {row?.recentContactAttempt?.nextContactDate &&
        moment(row?.recentContactAttempt?.nextContactDate).isValid()
          ? moment(row?.recentContactAttempt?.nextContactDate).format(
              "DD/MM/YYYY"
            ) +
            `\n${
              row?.recentContactAttempt?.nextContactTime
                ? row?.recentContactAttempt?.nextContactTime
                : ""
            }`
          : "-"}
      </span>
    ),
  },
  {
    text: "Lead Category",
    dataField: "leadCategory",
    formatter: (cell, row) => (
      // <span></span>
      <span
        style={{
          color: LEAD_CATEGORY_COLOR[row?.leadCategory],
          fontWeight: "bold",
        }}
      >
        {row?.leadCategory
          ? row?.leadCategory?.charAt(0).toUpperCase() +
            row?.leadCategory?.slice(1)
          : ""}
      </span>
    ),
  },
  {
    text: "Last Interest Level",
    formatter: (cell, row) =>
      //<span>{row?.recentContactAttempt?.customerInterestLevel}</span>
      //for(var i=0;i<row?.recentContactAttempt?.customerInterestLevel;i++){
      row?.recentContactAttempt?.customerInterestLevel
        ? CUSTOMER_INTEREST_LEVEL.find((element) => {
            return (
              element.value ===
              row?.recentContactAttempt?.customerInterestLevel
            );
          })?.label
        : "-",
  },

  {
    //dataField: 'fullName',
    text: "Customer Name",
    formatter: (cell, row) => (
      <span>{row?.firstName + " " + row?.lastName}</span>
    ),
  },
  {
    text: "Loan Offer Amount",
    formatter: (cell, row) => (
      <span>
        {currencyFormat(
          Math.ceil(
            row?.recentContactAttempt?.loanOfferAmount || row?.loanOfferAmount
          )
        )}
      </span>
    ),
  },
  // {
  //   text: 'Phone Number',
  //   formatter : (cell,row)=>(
  //     <span>{row?.recentContactAttempt?.customerNewPhoneNumber || row?.phoneNumber}</span>
  //   )
  // },

  {
    text: "",
    formatter: (cell, row) => (
      <span>
        <Link to={`/customers/${row.uuid}`}>
          <Button type="button" color="blue">
            Record result
          </Button>
        </Link>
      </span>
    ),
  },
];
