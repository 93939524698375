import { toastr } from "react-redux-toastr";
import { Label } from "reactstrap";

export const TVS_FORMAT_DATE = 'DD/MM/yyyy';
export const DEFAULT_COUNTRY_CODE = '+63'
export const BE_FORMAT_DATE = 'yyyy-MM-DD';
export const PROCESSING_FEES_FRACTION = 0.02;
export const BaseURL = 'https://z431itk1h4.execute-api.ap-southeast-1.amazonaws.com/dev'; //dev
// export const BaseURL = 'https://gkoyx27ngb.execute-api.ap-southeast-1.amazonaws.com/production';
export const CommsEngineURL = 'https://bubiwvunjj.execute-api.ap-southeast-1.amazonaws.com/dev'; //dev 
// export const CommsEngineURL = 'https://55tbj095bd.execute-api.ap-southeast-1.amazonaws.com/production';
export const ACCURACY_MAPPING = {
  LOW: {
    color: '#CF0000',
    text: 'Low',
  },
  MED: {
    color: '#F35F04',
    text: 'Medium',
  },
  HIGH: {
    color: '#00A061',
    text: 'High',
  },
};

export const formatCurrency = (value) => {
  if (!value) return '';
  const num = value?.toString().replace(/,/gi, '');
  const splitDecima = num.toString().split('.');
  const num2 = splitDecima[0].split(/(?=(?:\d{3})+$)/).join(',');
  return `${num2}${splitDecima[1] ? `.${splitDecima[1]}` : '.00'}`;
};

export const PAYMENT_NOTES = ['Full Payment', 'Partial Payment', 'Promise to Pay'];

export const PTP_LOCATION = ['Branch', 'Field'];

export const OWNER_OF_PHONE_NUMBER = [
  'Customer',
  'Parent / Parent-in-law',
  'Sibling',
  'Child / Grandchild',
  'Grandparent',
  'Spouse / Partner',
  'Friend',
  'Other',
];

export const CASHIER_ROLE = 'A';
export const MANAGER_ROLE = 'M';
export const COOPERATOR_ROLE = 'C';
export const COLLECTION_SPECIALIST = 'F';

export const PARTIAL_PAYMENT = 'Partial Payment';
export const PROMISE_TO_PAY = 'Promise to Pay';
export const CALL_BACK_LATER = 'Call Back Later';
export const FULL_PAYMENT = 'Full Payment';
export const ALREADY_MADE_PAYMENT = 'Already Made Payment';
export const NOT_CONTACTABLE = 'Not Contactable';
export const LEFT_MESSAGE = 'Left Message';

export const isStyleObject = (obj) => typeof obj === 'object';
export const IDLE_TIMEOUT = 1800;

export const ROLES = [
  {
    id: COOPERATOR_ROLE,
    value: COOPERATOR_ROLE,
    label: "Corporate Manager"
  },
  {
    id: MANAGER_ROLE,
    value: MANAGER_ROLE,
    label: "Manager"
  },
  {
    id: CASHIER_ROLE,
    value: CASHIER_ROLE,
    label: "Cashier"
  },
  {
    id: COLLECTION_SPECIALIST,
    value: COLLECTION_SPECIALIST,
    label: "Collection Specialist"
  }
]
export const SALES_STAGES = ['Not started','In progress','Interested','Approved','Dropped','Released'];
export const SALES_STAGES_VALUES =

  {
    'All sales stages': '',
    
    'Not started': 'not-started',
  
    'In progress': 'in-progress',
  
    'Interested': 'interested',

    'Approved': 'approved',

    'Released': 'released',

    'Dropped': 'dropped',

  }
  
export const ADDRESS_TYPE = [
  {
    id: 'Home',
    value: 'Home',
    label: 'Home',
  },
  {
    id: 'Office',
    value: 'Office',
    label: 'Office',
  },
  {
    id: 'Relative',
    value: 'Relative',
    label: 'Relative',
  },
  {
    id: 'Comaker',
    value: 'Comaker',
    label: 'Comaker',
  },
  {
    id: 'Other',
    value: 'Other',
    label: 'Other',
  },
];

export const SALES_STAGES_DD = [
  {
    id: 'not-started',
    value: 'not-started',
    label: 'Not started',
  },
  {
    id: 'in-progress',
    value: 'in-progress',
    label: 'In progress',
  },
  {
    id: 'interested',
    value: 'interested',
    label: 'Interested',
  },
  {
    id: 'approved',
    value: 'approved',
    label: 'Approved',
  },
  {
    id: 'released',
    value: 'released',
    label: 'Released',
  },
  {
    id: 'dropped',
    value: 'dropped',
    label: 'Dropped',
  },
]

export const NOT_STARTED_CONTACT_TAG = [
  {
    id: 'not-started',
    value: 'not-started',
    label: 'Not Started'
  }
]

export const IN_PROGRESS_CONTACT_TAG = [
  {
    id: 'need-follow-up',
    value: 'need-follow-up',
    label: 'Need follow up',
  },
  {
    id: 'no-response',
    value: 'no-response',
    label: 'No response',
  },
  {
    id: 'wrong-phone-no',
    value: 'wrong-phone-no',
    label: 'Wrong phone number',
  },
  {
    id: 'call-disconnected',
    value: 'call-disconnected',
    label: 'Call disconnected',
  },
  {
    id: 'not-contactable',
    value: 'not-contactable',
    label: 'Not contactable',
  },
];

export const SUCCESS_CONTACT_TAG = [
  {
    id: 'approved',
    value: 'approved',
    label: 'Approved',
  },
];

export const RELEASED_CONTACT_TAG = [
  {
    id: 'released',
    value: 'released',
    label: 'Released',
  },
];

export const FAILURE_CONTACT_TAG = [
  {
    id: 'not-interested',
    value: 'not-interested',
    label: 'Not interested',
  },
  {
    id: 'wrong-phone-no',
    value: 'wrong-phone-no',
    label: 'Wrong phone number',
  },
  {
    id: 'dnc-customer',
    value: 'DNC customer',
    label: 'DNC customer',
  },
  {
    id: 'not-eligible',
    value: 'not-eligible',
    label: 'Not eligible',
  },
  {
    id: 'not-contactable',
    value: 'not-contactable',
    label: 'Not contactable',
  },
]

export const CONTACT_TAG_VALUES = {
  'Not Started': 'not-started',
  'Need follow up': 'need-follow-up',
  'No response': 'no-response',
  'Wrong phone number': 'wrong-phone-no',
  'Call disconnected': 'call-disconnected',
  'Approved': 'approved',
  'Not interested': 'not-interested',
  'DNC customer': 'dnc-customer',
  'Not eligible': 'not-eligible',
  'Not contactable': 'not-contactable',
  'Requires approval': 'requires-approval'
}

export const LOAN_TENURE = [
  { label: "12 months", value: 12, id: 12},
  { label: "24 months", value: 24, id: 24 },
  { label: "36 months", value: 36, id: 36 },
];


export const CUSTOMER_INTEREST_LEVEL =[
// { label: "-", value: 0, id: 0 },
{ label: "🔥", value: 1, id: 1 },
{ label: "🔥🔥", value: 2, id: 2 },
{ label: "🔥🔥🔥", value: 3, id: 3 }
]

export const TIME_RANGE = [
  {
    label: "Before 10 am",
    id: "Before 10 am",
    value: "Before 10 am",
  },
  {
    id: '10 - 1 pm',
    value: '10 - 1 pm',
    label: '10 - 1 pm',
  },
  {
    id: '1 - 4 pm',
    value: '1 - 4 pm',
    label: '1 - 4 pm',
  },
  {
    id: '4 - 6 pm',
    value: '4 - 6 pm',
    label: '4 - 6 pm',
  },
  {
    id: 'After 6 pm',
    value: 'After 6 pm',
    label: 'After 6 pm',
  },
]

export const LEAD_SCORE_MAPPING = {
  1 : '1-High',
  2: '2-Med',
  3: '3-Low'
}

export const LEAD_SCORE_COLOR = {
  1:'green',
  2:'orange',
  3:'red'
}

export const LEAD_CATEGORY_COLOR = {
  'warm':'orange',
  'hot':'red'
}

export const CONTACT_CHANNELS = {
 "sms" : "SMS",
 "agent" : "Direct call",
  "ivr" : "IVR",
  "email" : "Email"  
}

export const RESEND_COUNTDOWN = 300; // resend otp countdown for user verification in seconds

export const TOASTR_ERROR_OPTIONS = {
  timeOut: 600000000,
  onCloseButtonClick: () => {
    toastr.clean();
  },
  showCloseButton: true,
  closeOnToastrClick: false,
};

export const ANALYTICS_ENABLED = false;
export const SHOW_LOGO = false;