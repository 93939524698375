import { GET_CONTACT_HISTORY, GET_CONTACT_HISTORY_SUCCESS, GET_CONTACT_HISTORY_FAIL } from "../actions/types";

const initialState = {
    pending: false,
    success: null,
    error: null,
    loading: true,
    data: [],
    otherInfo: {},
}

export default function(state = initialState, action){
    const{type, payload}=action;
    switch(type){
        case GET_CONTACT_HISTORY:
            return ({
                ...state,
                pending : true,
                success : null,
                error : null,
                data : null
            })

        case GET_CONTACT_HISTORY_SUCCESS:
            const { list, ...rest } = payload;
            console.log(payload)
            return ({
                ...state,
                pending : false,
                success : true,
                error : null,
                data : list || null,
                otherInfo : rest || {}
            })

        case GET_CONTACT_HISTORY_FAIL:
            return({
                pending : false,
                success : false,
                error : payload.error,
                data : null
            })
        
        default:
            return state
    }
}