import React from 'react';
import moment from 'moment';
import { currencyFormat } from '../../utils/helpers';


export const getContactHistoryCols = () => [
   
    {
        text: 'Timestamp',
        formatter : (cell,row)=>(
          <span>{moment(row?.recordDate).format("DD/MM/YYYY hh:mm:ss")}</span>
        )
      },

      {
        text: 'Sales Agent',
        formatter : (cell,row)=>(
          <span>{row?.salesAgent?.firstName? row?.salesAgent?.firstName+' '+row?.salesAgent?.lastName : '--'}</span>
        )
      },

    {
        dataField: 'salesStage',
        text: 'Sale Stage',
      },
     
      {
        dataField: 'contactTag',
        text: 'Contact Tag',
      },

      {
        dataField: 'contactChannel',
        text: 'Contact Channel',
      },

      {
        dataField: 'customerNewPhoneNumber',
        text: 'Contact Number',
      },

      

  {
    text: 'Interested Loan Offer',
    formatter : (cell,row)=>(
      <span>{currencyFormat(row?.loanOfferAmount)}</span>
    )
  },

  {
    text: 'Remarks',
    dataField: 'remarks'
  },
];
