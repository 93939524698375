import { USER_LOADED, AUTH_ERROR,
     LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, LOGOUT_FAIL, REQUEST_EMAIL_OTP_SUCCESS, REQUEST_EMAIL_OTP_FAIL, VERIFY_EMAIL_OTP_SUCCESS, VERIFY_EMAIL_OTP_FAIL, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL, RESET_AUTH_STATE } from "../actions/types";

const initialState = {
    token : localStorage.getItem('token'),
    passwordResetToken : null,
    isAuthenticated : false,
    loading : true,
    user : null,
    error : false
}

export default function(state = initialState,action){
    const{ type, payload } = action;

    switch(type){
        case USER_LOADED:
            return{
                ...state,
                isAuthenticated : true,
                loading : false,
                error : false,
                user : payload
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('token',payload.access_token);
            return {
                ...state,
                token : payload.access_token,
                user: payload.userDetails,
                isAuthenticated : true,
                loading : false,
                error : false
            }
        case AUTH_ERROR:
        case LOGIN_FAIL:
            return{
                ...state,
                token: null,
                isAuthenticated: false,
                loading:false,
                user: null,
                error: true
            }
        case LOGOUT:
            localStorage.removeItem('token');
            return{
                ...state,
                token: null,
                isAuthenticated: false,
                loading:false,
                user: null,
                error: false
            }
        case REQUEST_EMAIL_OTP_SUCCESS:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: false
            }
        case REQUEST_EMAIL_OTP_FAIL:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: payload || true
            }
        case VERIFY_EMAIL_OTP_SUCCESS:
            localStorage.setItem('reset_password_token',payload.token);
            return {
                ...state,
                token: null,
                passwordResetToken: payload.token,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: false
            }
        case VERIFY_EMAIL_OTP_FAIL:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: payload || true
            }
        case RESET_PASSWORD_SUCCESS:
            localStorage.removeItem('reset_password_token');
            return {
                ...state,
                token: null,
                passwordResetToken: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: false
            }
        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: payload || true
            }
        case RESET_AUTH_STATE:
            localStorage.removeItem('reset_password_token');
            return initialState;
        default:
            return state;
    }
}