import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { logout, postEulaDetail } from '../../actions/auth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

const link = 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_webapp.txt';
//const link = Localization.eulaTextUrl;

const Term = ({ userId, version, setShowTerm, logout, postEulaDetail, eula  }) => {
  const [content, setContent] = useState('');
  const [agree, setAgree] = useState(true);
  const getTermFile = () => {
    fetch(link)
      .then((r) => r.text())
      .then((text) => {
        setContent(text);
      });
  };

  useEffect(() => {
    getTermFile();
  }, []);

  const render = () => {
    return content.split('\n').map((item, key) => (
      <span key={key}>
        {item}
        <br />
      </span>
    ));
  };

  const handleSubmit = () => {
    if (agree) {
      postEulaDetail(userId, version);
    } else {
     logout();
    }
    setShowTerm(false);
  };

  return (
    <>
      <ModalHeader className="justify-content-center">
        <div
          style={{
            fontWeight: 500,
            fontSize: '20px',
            color: '#000',
          }}
        >
          End User License Agreement
        </div>
      </ModalHeader>
      <ModalBody className="p-0">
        <div
          style={{
            maxHeight: '500px',
            overflowY: 'scroll',
            padding: '30px',
          }}
        >
          {render()}
        </div>
        <div
          style={{
            boxShadow: '0px -3px 5px rgba(0, 0, 0, 0.15)',
            padding: '10px 30px 20px',
          }}
        >
          <Form>
            <FormGroup check className="mt-3">
              <Input
                type="checkbox"
                name="check"
                id="exampleCheck"
                defaultChecked={true}
                onChange={() => setAgree(!agree)}
              />
              <Label for="exampleCheck" check>
                I agree to the above Terms & Conditions
              </Label>
            </FormGroup>
          </Form>
          {!agree && <div className="text-danger">If you reject the Terms & Conditions, you will be logged out.</div>}
        </div>
      </ModalBody>
      <ModalFooter className="text-center justify-content-center">
        <Button onClick={() => handleSubmit()} color="link border-0" style={{ fontWeight: 500, fontSize: '20px' }}>
          I Agree
        </Button>
      </ModalFooter>
    </>
  );
};

Term.propTypes = {
    userId : PropTypes.any,
    version : PropTypes.string,
    logout : PropTypes.func.isRequired,
    postEulaDetail : PropTypes.func.isRequired,
}

const mapStateToProps = state =>({
    eula : state.eula    //whtever is required from reducer
})
export default connect(mapStateToProps,{logout, postEulaDetail})(Term);
