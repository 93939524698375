import { GET_EULA_DETAILS, GET_EULA_DETAILS_FAILED, POST_EULA_DETAILS_FAILED,
POST_EULA_DETAILS } from "../actions/types";

const initialState = {
    loading : true,
    data : null,
    error : false
};

export default function(state = initialState,action){
    const{ type, payload } = action;
    switch(type) {
        case GET_EULA_DETAILS:
            return({
                ...state,
                loading : false,
                data : payload || null,
                error : false
            })
        case GET_EULA_DETAILS_FAILED:
            return({
                ...state,
                loading : false,
                data : payload,
                error : true
            })
        default:
            return initialState
    }
}