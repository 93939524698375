/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  SalesStageDropdown,
  SalesAgentDropdown,
} from "../../components/elements";
import { Container, Label } from "reactstrap";
import Boundary from "../../components/layouts/Boundary";
import { Table } from "../../components/elements";
import styles from "styled-components";
import { getProspectList, getUserList } from "../../actions/prospectList";
import { getProspectListingCols } from "./columns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, CardBody, Modal } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import { Link } from "react-router-dom";
import moment from "moment";
import { ANALYTICS_ENABLED, SALES_STAGES, SALES_STAGES_VALUES } from "../../constant";
//import { getBranchList } from '../../actions/startup';
import { useLocation } from "react-router";
import { getEulaDetail, loadUser } from "../../actions/auth";
import Term from "../../components/tvs-components/Term";
import userList from "../../reducers/userList";
import Header from "../../components/layouts/Header";
import Footer from "../../components/layouts/Footer";
import AppContext from "../../utils/index";
import Analytics from "../../components/web-services/firebase";
import setAuthToken from "../../utils/setAuthToken";
import store from "../../store";

const ProspectListingContainer = ({
  getEulaDetail,
  prospectList,
  getProspectList,
  getUserList,
  eula,
  auth,
  userList,
}) => {
  const { tableState, setTableState } = useContext(AppContext);
  const [state, setState] = useState([]);
  const [total, setTotal] = useState(0);
  const [salesStage, setSalesStage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState("Prospect");
  const [version, setVersion] = useState("1.0.0");
  const [isShowTerm, setShowTerm] = useState(false);
  const [userFilter, setUserFilter] = useState([]);
  const [userFilterValues, setUserFilterValues] = useState({});

  var role = "";
  var headerTitle = "Prospect";

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      store.dispatch(loadUser());
    }

    getEulaDetail(auth?.user?.id);
    getUserList();
    setSalesStage(SALES_STAGES);

    // log listing page visited event to firebase analytics
    ANALYTICS_ENABLED && Analytics.logEvent(
      Analytics.analytics,
      Analytics.EVENT_TYPE.listingPageVisited,
      {
        user_id: auth?.user?.id,
        role: auth?.user?.role,
      }
    );

    // set current screen to listing page
    ANALYTICS_ENABLED && Analytics.logEvent(Analytics.analytics, "screen_view", {
      firebase_screen: Analytics.EVENT_TYPE.listingPage,
      firebase_screen_class: Analytics.EVENT_TYPE.listingPage,
      user_id: auth?.user?.id,
      role: auth?.user?.role,
    });
  }, []);

  useEffect(() => {
    const arr = [];
    const userFilterValues = {};
    userList?.data?.forEach((user) => {
      const name = user?.firstName + " " + user?.lastName;
      arr.push(name);
      userFilterValues[name] = user?.id;
    });
    setUserFilter(arr);
    setUserFilterValues(userFilterValues);
    // console.log(userFilterValues);
  }, [userList]);

  useEffect(() => {
    if (
      eula?.loading === false &&
      eula?.error === true &&
      auth?.user?.userTc?.hasAccepted === false
    ) {
      setShowTerm(true);
    }
    if (
      eula?.loading === false &&
      eula?.error === true &&
      auth?.user?.userTc === null
    ) {
      setShowTerm(true);
    }
  }, [eula]);

  useEffect(() => {
    getProspectList(tableState);
  }, [tableState]);

  const onTableChange = (type, value) => {
    if (type === "pagination") {
      setTableState({
        ...tableState,
        page: value.page,
      });
    } else if (type === "search") {
      setTableState({
        ...tableState,
        page: 1,
        filter: {
          ...tableState.filter,
          search: value,
        },
      });
    }
  };

  useEffect(() => {
    setLoading(prospectList?.pending || false);
    setState(prospectList?.data || []);
    setTotal(prospectList?.otherInfo?.count || 0);
  }, [prospectList]);

  const handleChangeSalesStage = (item) => {
    const val = SALES_STAGES_VALUES[`${item}`];
    console.log("Sales stage value is : " + val);
    setTableState({
      ...tableState,
      page: 1,
      filter: {
        ...tableState.filter,
        salesStage: val,
        salesStageLabel: item,
      },
    });
  };

  const handleChangeSalesAgent = (item) => {
    let salesAgentName;
    var val;
    if (item !== 0) {
      salesAgentName = item;
      val = userFilterValues[`${item}`];
    } else {
      val = item;
    }
    console.log(val);
    setTableState({
      ...tableState,
      page: 1,
      filter: {
        ...tableState.filter,
        salesAgent: val,
        salesAgentName,
      },
    });
  };

  return (
    <>
      <Header />
      <div
        style={{
          marginTop: "20px",
        }}
      ></div>
      <Container>
        <div className="pt-4 pb-2">
          <h1>Prospect Listing</h1>
          <H3>Updated {`${moment().format("DD MMM yy hh:mm a")}`}</H3>
          <div className=" d-flex justify-content-end">
            <SalesAgentDropdown
              data={userFilter}
              handleChange={handleChangeSalesAgent}
              header={"Sales Agent"}
            />
            <div className="mx-4">
              <SalesStageDropdown
                data={salesStage}
                handleChange={handleChangeSalesStage}
                header={"Sales Stage"}
              />
            </div>
          </div>
        </div>

        <Card className="my-4">
          <CardBody>
            <Label
              style={{
                color: "#2A2A3E",
              }}
            >
              Search by Name or phone number
              <span
                style={{ color: "#2A2A3E", opacity: 0.5, marginLeft: "10px" }}
              >
                (Minimum 3 characters)
              </span>
            </Label>
            <div className="position-relative">
              <DebounceInput
                minLength={3}
                debounceTimeout={500}
                onChange={(event) =>
                  onTableChange("search", event.target.value)
                }
                placeholder="Type here"
                className="form-control"
                value={tableState?.filter?.search || ""}
              />
              {tableState?.filter?.search?.length >= 3 && (
                <Button
                  color="x"
                  onClick={() => onTableChange("search", "")}
                  className="position-absolute circle-close"
                  style={{
                    width: "20px",
                    height: "20px",
                    top: "15px",
                    right: "15px",
                  }}
                />
              )}
            </div>
          </CardBody>
        </Card>
        <Boundary loading={loading}>
          <>
            <div className="table-note">
              <Label className="label-color">
                {total > tableState.sizePerPage * tableState.page
                  ? tableState.sizePerPage * tableState.page
                  : total}{" "}
                of {total} entries
              </Label>
            </div>
            <div>
              <Table
                data={state}
                page={tableState.page}
                columns={getProspectListingCols()}
                sizePerPage={tableState.sizePerPage}
                totalSize={total}
                onTableChange={onTableChange}
                keyField="id"
              />
            </div>
          </>
        </Boundary>
        <Modal isOpen={isShowTerm} size="lg" centered>
          <Term
            version={version}
            setShowTerm={setShowTerm}
            userId={auth?.user?.id}
          />
        </Modal>
      </Container>
      <Footer />
    </>
  );
};

const H3 = styles.h3`
  opacity: 0.8;
`;

ProspectListingContainer.propTypes = {
  prospectList: PropTypes.object.isRequired,
  getProspectList: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  prospectList: state.prospectList,
  eula: state.eula,
  auth: state.auth,
  userList: state.userList, //whtever is required from reducer
});
export default connect(mapStateToProps, {
  getProspectList,
  getEulaDetail,
  getUserList,
})(ProspectListingContainer);
