/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Label } from "reactstrap";
import Boundary from "../../components/layouts/Boundary";
import { Table } from "../../components/elements";
import styles from "styled-components";
import { getContactHistory } from "../../actions/prospect";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import moment from "moment";
import { getContactHistoryCols } from "./columns";

const ContactHistoryTable = ({ contactHistoryLoading, contactHistoryData }) => {
  const [tablePage, setTablePage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [contactHistoryLength, setContactHistoryLength] = useState(0);

  const sizePerPage = 5;

  useEffect(() => {
    if (!contactHistoryLoading) {
      setTableData(contactHistoryData.slice(0, sizePerPage));
      setContactHistoryLength(contactHistoryData.length);
    }
  }, [contactHistoryLoading]);

  useEffect(() => {
    setTableData(
      contactHistoryData.slice(
        (tablePage - 1) * sizePerPage,
        (tablePage - 1) * sizePerPage + sizePerPage
      )
    );
  }, [tablePage]);

  const onTableChange = (type, value) => {
    if (type === "pagination") {
      setTablePage(value.page);
    }
  };

  return (
    <>
      <div className="pt-4 pb-2">
        <h1>Contact Record History</h1>
        <H3>Last Updated {`${moment().format("DD/MM/YYYY hh:mm a")}`}</H3>
      </div>
      <Boundary loading={contactHistoryLoading}>
        <>
          <div className="table-note">
            <Label className="label-color">
              {contactHistoryLength > sizePerPage * tablePage
                ? sizePerPage * tablePage
                : contactHistoryLength}{" "}
              of {contactHistoryLength} entries
            </Label>
          </div>
          <div>
            <Table
              data={tableData}
              page={tablePage}
              columns={getContactHistoryCols()}
              sizePerPage={sizePerPage}
              totalSize={contactHistoryLength}
              onTableChange={onTableChange}
              keyField="id"
            />
          </div>
        </>
      </Boundary>
    </>
  );
};

const H3 = styles.h3`
  opacity: 0.8;
`;

export default ContactHistoryTable;
