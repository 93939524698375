import { GET_PROSPECT, GET_PROSPECT_SUCCESS, GET_PROSPECT_FAIL, CLEAR_CURRENT_PROSPECT } from "../actions/types";

const initialState = {
    pending: false,
    success: null,
    error: null,
    loading: true,
    data: {}
}

export default function(state = initialState, action){
    const{type, payload}=action;

    switch(type){
        case GET_PROSPECT:
            return ({
                ...state,
                pending : true,
                success : null,
                error : null,
                data : {}
            })

        case GET_PROSPECT_SUCCESS:
            return ({
                ...state,
                pending : false,
                success : true,
                error : null,
                loading : false,
                data : payload
            })

        case GET_PROSPECT_FAIL:
            return({
                pending : false,
                success : false,
                error : payload,
                loading : false,
                data : {}
            })

        case CLEAR_CURRENT_PROSPECT:
            return initialState;
        
        default:
            return state
    }
}