// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCn7bKm1FSdELdlycQk48KynF-IzfHUYHY",
  authDomain: "cdi-salestracker-3e2e1.firebaseapp.com",
  projectId: "cdi-salestracker-3e2e1",
  storageBucket: "cdi-salestracker-3e2e1.appspot.com",
  messagingSenderId: "951848863443",
  appId: "1:951848863443:web:038dd0e89bef0d84fab626",
  measurementId: "G-8YNJG3PN54"
};

const EVENT_TYPE = {
    listingPageVisited: 'LISTING_PAGE_VISITED',
    recordResultPageVisited: 'RECORD_RESULT_PAGE_VISITED',
    loginPageVisited: 'LOGIN_PAGE_VISITED',
    listingPage: 'LISTING_PAGE',
    recordResultPage: 'RECORD_RESULT_PAGE',
    loginPage: 'LOGIN_PAGE',
    appLoad: 'APP_LOADED'
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  
  const Analytics = {
    analytics,
    logEvent,
    EVENT_TYPE,
    setUserId
  };
  
  export default Analytics;