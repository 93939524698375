import { GET_USER_LIST, GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILED } from "../actions/types";

const initialState = {
    pending: false,
    success: null,
    error: null,
    loading: true,
    data: [],
}

export default function(state = initialState, action){
    const{type, payload}=action;
    switch(type){
        case GET_USER_LIST:
            return ({
                ...state,
                pending : true,
                success : null,
                error : null,
                data : null
            })

        case GET_USER_LIST_SUCCESS:
            console.log(payload)
            return ({
                ...state,
                pending : false,
                success : true,
                error : null,
                data : payload || null
            })

        case GET_USER_LIST_FAILED:
            return({
                pending : false,
                success : false,
                error : payload.error,
                data : null
            })
        
        default:
            return state
    }
}