import { combineReducers} from 'redux';
import auth from './auth';
import prospectList from './prospectList';
import prospect from './prospect';
import contactHistory from './contactHistory';
import updateRecord from './updateRecord';
import updateProspect from './updateProspect';
import eula from './eula';
import { reducer as toastrReducer } from 'react-redux-toastr';
import userList from './userList';
export default combineReducers({auth, toastr: toastrReducer, prospectList, prospect, 
    contactHistory, updateRecord, updateProspect, eula, userList});